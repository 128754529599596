import {combineReducers} from "redux";
import {ModeReducer} from "store/mode/reducer";
import {ViewerReducer} from "store/viewer/reducer";
import {LoaderReducer} from "store/loader/reducer";
import {StaticDataReducer} from "store/static-data/reducer";
import {ProjectReducer} from "store/project/reducer";
import {ImagesReducer} from "store/images/reducer";
import {ThemeReducer} from "store/theme/reducer";
import {AuthenticationReducer} from "./authentication/reducer";

export default combineReducers({
    project: ProjectReducer,
    staticData: StaticDataReducer,
    mode: ModeReducer,
    viewer: ViewerReducer,
    loader: LoaderReducer,
    images: ImagesReducer,
    theme: ThemeReducer,
    authentication: AuthenticationReducer
});