import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

   const currentTheme = theme.widgets.socialIcon;

    return {
        root: currentTheme.root,
        icon: currentTheme.icon
    }

});

export default classes;

