import {loggedUserGetter} from "./logged-user-getter";
import {isUserLoggedIn} from "./is-user-logged-in";

export const authHeader = () => {
    // return authorization header with jwt token
    let user = loggedUserGetter();

    if (isUserLoggedIn()) {
        return { 'Authorization': 'Bearer ' + user.token };
    }

    return {};
}