import React, {useEffect, useLayoutEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {addNewPage, removePage} from "store/pages/action-creator";
import {store} from "react-notifications-component";
import {NotificationFactory} from "components/notification/notification";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {FaRedo, FaTrash} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./pages.styles";
import {Utilities} from "utilities/utilities";
import history from "../../helpers/history";
import {Constants} from "../../constants";
import {useLocation} from "react-router";
import {loadCmsData} from "../../applications/project/actions/actions";

export default () => {

    const classes = CreateTheme(useStyles);

    const project = useSelector(store => store.project);

    const dispatch = useDispatch();

    const urlInputRef = React.useRef();

    const {handleSubmit, register, errors, getValues, setValue, reset} = useForm();

    const location = useLocation();

    React.useEffect = Utilities.SSR.canUseDOM() ? useEffect : useLayoutEffect;

    useEffect(() => {
        const getData = async () => {
            dispatch(await loadCmsData(location));
        }

        if (urlInputRef.current) {
            register(urlInputRef.current, {required: true});
        }

        getData()

    }, [register, dispatch, location]);

    const onSubmit = (pageForm) => {
        const newPage = {};
        //newPage
        newPage.name = pageForm.name;
        newPage.isEdited = false;
        newPage.url = pageForm.url.startsWith("/") ? pageForm.url : `/${pageForm.url}`;
        newPage.metaData = {
            title: pageForm.title,
            description: pageForm.description,
            keywords: pageForm.keywords
        };
        newPage.sections = [];
        newPage.html = "";

        dispatch(addNewPage(newPage, project));

        reset();

        store.addNotification(NotificationFactory.create("Stránky upraveny!", "Nezapomeňte před opuštěním uložit.", "info"));
    };

    const removeHandle = (pageId) => {
        dispatch(removePage(pageId, project.pages));
    }

    const generateUrlHandle = () => {
        const values = getValues();

        if (values.name !== "") {
            const nameWithoutDiacritics = values.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const url = nameWithoutDiacritics.replace(/\s+/g, '-').toLowerCase();

            setValue("url", url);
        }
    };

    return (
        <div className={"pages--page"}>
            <Container>

                <h2 className={classes.header}>Seznam stránek</h2>

                <div className={classes.headline}>
                    <Row spacing={3}>
                        <Col xs={12} sm={3}>
                            <span className={classes.col}>{"#"}</span>
                        </Col>
                        <Col xs={12} sm={3}>
                            <span className={classes.col}>{"Název stránky"}</span>
                        </Col>
                        <Col xs={12} sm={3}>
                            <span className={classes.col}>{"Url adresa"}</span>
                        </Col>
                        <Col xs={12} sm={3}>
                            <span className={classes.col}>{"Akce"}</span>
                        </Col>
                    </Row>
                </div>

                {project.pages.map((page, index) => (
                    <div key={index} className={classes.page}>
                        <Row spacing={3}>
                            <Col xs={12} sm={3}>
                                <span className={classes.col}>{page.id}</span>
                            </Col>
                            <Col xs={12} sm={3}>
                                <span className={classes.col}>{page.name}</span>
                            </Col>
                            <Col xs={12} sm={3}>
                                <span className={classes.col}>{page.url}</span>
                            </Col>
                            <Col xs={12} sm={3}>
                                <div>
                                    {/*<EditIcon className={"pages__action-icon"}/>*/}
                                    <FaTrash className={classes.actionIcon} onClick={() => removeHandle(page.id)}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}

                <h2 className={classes.header}>Přidat novou stránku</h2>

                <form onSubmit={handleSubmit(onSubmit)}>

                    {errors.name &&
                    <Alert variant="danger"><span><strong>Název stránky</strong> je povinný</span></Alert>}
                    {errors.url && <Alert variant="danger"><span><strong>Url stránky</strong> je povinný</span></Alert>}
                    {errors.title &&
                    <Alert variant="danger"><span><strong>Titulek stránky</strong> je povinný</span></Alert>}
                    {errors.description &&
                    <Alert variant="danger"><span><strong>Popisek stránky</strong> je povinný</span></Alert>}

                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Control name={"name"} type="text" placeholder="Název stránky" ref={register({
                                    required: 'Required'
                                })}/>
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                            <div className={"pages__url-generator d-flex align-items-center"}>

                                <Form.Control name={"url"} type="text" placeholder="Url stránky" ref={register({
                                    required: 'Required'
                                })}/>

                                <FaRedo className={"pages__action-icon"} onClick={generateUrlHandle}/>


                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Control name={"title"} type="text" placeholder="Titulek" ref={register({
                                    required: 'Required'
                                })}/>
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Control name={"keywords"} type="text" placeholder="Klíčová slova" ref={register}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Control name={"keywords"} as="textarea" rows="3" placeholder="Popisek"
                                              ref={register({
                                                  required: 'Required'
                                              })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <br/>

                    <div className={"d-flex align-items-center justify-content-between"}>
                        <Button type={"button"} className={classes.submit}
                                onClick={() => history.push(Constants.Routes.Homepage)}
                                variant={"secondary"}>{"Zpět na projekt"}</Button>
                        <Button className={classes.submit} variant={"primary"} type="submit">Potvrdit</Button>
                    </div>

                </form>
            </Container>
        </div>
    );
};