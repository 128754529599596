import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.multiCols;

    return {
        col: {
            textAlign: currentTheme.col.textAlign
        }
    }

});

export default classes;

