import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.sectionWidgets;

    return {
        root: currentTheme.root,
        wrap: currentTheme.wrap,
        deleteIcon: currentTheme.deleteIcon
    }
});

export default classes;

