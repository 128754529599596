import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.uploadableImage;

    return {
        root: {
            ...currentTheme.root,
        },

        rootEditMode: {
            extend: "root",
            "&:hover $uploadImage": currentTheme.uploadImageHover,
            "&:hover $children": currentTheme.childrenHover
        },

        uploadImage: currentTheme.uploadImage,
        children: currentTheme.children
    }
});

export default classes;

