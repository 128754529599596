import React from 'react'
import Dropzone from 'react-dropzone'
import {FaCloudUploadAlt} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {uploadImages} from "store/images/actions/action-creator";
import {CreateTheme} from "hooks/theme";
import useStyles from "./file-upload.styles";
import {loggedUserGetter} from "../../helpers/logged-user-getter";

export const FileUpload = () => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();
    const project = useSelector(s => s.project);

    const uploadHandle = async (selectedFiles) => {
        if(selectedFiles.length > 0) {
            const user = loggedUserGetter();
            await dispatch(uploadImages(user.id, project.id, selectedFiles))
        }
    };

    return (
        <div className={"file-upload--component"}>
            <div className={classes.root}>
                <Dropzone accept={["image/jpeg", "image/png", "image/svg+xml"]} onDrop={uploadHandle}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className={classes.uploadArea}>
                                    <FaCloudUploadAlt className={classes.icon}/>
                                    <p className={classes.uploadText}>Sem přetáhněte soubory nebo klikněte pro
                                        procházení</p>
                                </div>

                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        </div>
    );
}

