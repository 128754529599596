import {createUseStyles} from 'react-jss'

const classes = createUseStyles((theme) => {

    const currentTheme = theme.widgets.imageWithText;

    return {
        root: currentTheme.root,
        textContent: currentTheme.textContent
    }
});

export default classes;