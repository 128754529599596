import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.widgets.actionButton;

    return {
        root: {
            ...currentTheme.root,
            "&:hover": currentTheme.rootHover,
            "& a": currentTheme.link,
            "& p": currentTheme.paragraph,
        },

        motiveWhite: {
            backgroundColor: currentTheme.motives.white.root.backgroundColor,
            "&:hover a": currentTheme.motives.white.linkHover,
            "& a": currentTheme.motives.white.link,
        },
        motiveBlack: { },
        motivePrimary: { },
        motiveSecondary: { },
        motiveTertiary: { }

    }
});

export default classes;

