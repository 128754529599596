import {components} from "../../base/components";
import {pages} from "../../base/pages";
import {Utilities} from "utilities/utilities";
import {modules} from "base/modules";

const calculateMultipliers = (min, max) => {
    let result = [];

    for (var i = min; i <= max; i += 10) {
        result.push(i);
    }

    return result;
}

export const defaultConfig = {

    fontSizes: {
        baseSize_05: 0.5,
        baseSize_10: 1,
        baseSize_15: 1.5,
        baseSize_20: 2,
        baseSize_25: 2.5,
        baseSize_30: 3,
        baseSize_35: 3.5,
        baseSize_40: 4,
        baseSize_45: 4.5,
        baseSize_50: 5,
        baseSize_55: 5.5,
        baseSize_60: 6
    },
    colors: {
        white: "#ffffff",
        black: "#000000",
        error: "#FE1700",
        warning: "#FEED00",
        success: "#008000",

        primary: "#008000",
        secondary: "",
        tertiary: "",

        gray1: "#eaeaea",
        gray2: "#f6f6f6"
    },

    grid: {
        breakpoints: {
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        }
    },

    imageMultiplier: {
        min: 0,
        max: 200,
        default: 100
    }
};

const theme = (config) => {

    config = config || defaultConfig;

    const {colors, fontSizes, grid, imageMultiplier} = config;

    const multiplierClasses = {};

    calculateMultipliers(imageMultiplier.min, imageMultiplier.max).forEach((value) => {
        multiplierClasses[`multiplier${value}`] = {
            "& img": {
                width: `${value}% !important`
            }
        }
    })

    return {

        //-------------Global fonts-------------
        base: {
            margin: 0,
            padding: 0,
            fontSize: "100%",

            ulLi: {},

            sectionWrapper: {
                position: "relative"
            },

            container: {
                maxWidth: "1560px"
            },

            p: {
                display: "block",
                margin: 0,
                padding: 0
            },

            a: {
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "none"
                }
            },

            contentWrap: {
                maxWidth: "unset",
                padding: 0
            },

            others: {}
        },
        fonts: {
            sourceLink: "https://fonts.googleapis.com/css2?family=Muli:wght@400;700;800&display=swap",
            families: {
                primary: "Muli",
                secondary: "Arial",
                tertiary: "Arial"
            }
        },

        headers: {
            h1: {
                fontSize: `${fontSizes.baseSize_50}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                    fontSize: `${fontSizes.baseSize_25}rem`,
                    padding: "1em 0 0 0"
                },

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                    fontSize: `${fontSizes.baseSize_20}rem`,
                    padding: "1em 0 0 0"
                }
            },
            h2: {
                fontSize: `${fontSizes.baseSize_45}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                    fontSize: `${fontSizes.baseSize_20}rem`,
                },

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                    fontSize: `${fontSizes.baseSize_15}rem`,
                }
            },
            h3: {
                fontSize: `${fontSizes.baseSize_40}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                    fontSize: `${fontSizes.baseSize_20}rem`,
                },

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                    fontSize: `${fontSizes.baseSize_15}rem`,
                }
            },
            h4: {
                fontSize: `${fontSizes.baseSize_40}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",
            },
            h5: {
                fontSize: `${fontSizes.baseSize_40}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",
            },
            h6: {
                fontSize: `${fontSizes.baseSize_40}rem`,
                margin: "1em 0 0 0",//(top, right, bottom, left)
                padding: "0 0 0 0",

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                    fontSize: `${fontSizes.baseSize_15}rem`,
                },

                [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                    fontSize: `${fontSizes.baseSize_10}rem`,
                }
            }
        },

        editor: {
            colors: Object.entries(colors).filter(([key, val]) => val !== "").map(([key, value]) => (
                {name: key, hex: value, rgb: Utilities.Jss.hexToRGB(value), className: `color-${key}`}
            ))
        },

        //-------------Modules-------------
        modules: modules,

        //-------------Pages-------------
        pages: pages,

        //-------------Components-------------
        components: components(config),

        //-------------Sections-------------
        sections: {
            default: {
                root: {},
                textAlign: "center",
                content: {
                    textAlign: "center"
                }
            },

            twoCols: {
                root: {
                },
                textAlign: "center",
                col: {
                    textAlign: "center",
                    width: "50%",
                    overflow: "hidden",
                    height: "100vh",

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                        width: "100%"
                    }
                },
                colWrap: {
                    position: "absolute",
                    left: 0,
                    top: 0,
                    display: "flex",
                    justifyContent: "center",

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                        flexDirection: "column"
                    }
                }
            },

            threeCols: {
                root: {
                    display: "flex",
                    justifyContent: "center",

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                        flexDirection: "column"
                    }
                },
                textAlign: "center",
                col: {
                    textAlign: "center",
                }
            },
            multiCols: {
                root: {
                    display: "flex",
                    justifyContent: "center",

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                        flexDirection: "column"
                    }
                },
                col: {
                    textAlign: "center",
                }
            },

            menu: {
                root: {
                    backgroundColor: colors.white,
                    padding: 0,
                },

                list: {
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                },

                link: {
                    padding: "1em",
                    display: "block",
                    color: colors.black,
                    textDecoration: "none"
                }
            },

            topBar: {
                borderBottom: `1px solid ${colors.gray1}`,
                wrap: {
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "5%"
                },

                icons: {
                    display: "flex",
                    padding: "1em",
                    alignItems: "center",
                },

                iconsSvg: {
                    marginRight: "0.5em",
                    fontSize: `${fontSizes.baseSize_10}em`,
                    fill: colors.primary
                },

                iconPhone: {
                    borderRight: `1px solid ${colors.gray1}`
                }
            },

            imageFloat: {},

            separator: {
                root: {
                    height: "1px",
                    width: "100%",
                    backgroundColor: colors.black
                }
            },

            carousel: {
                wrap: {
                    maxHeight: "42em"
                },

                content: {
                    position: "relative",
                    maxHeight: "42em",
                    minHeight: "2em"
                },

                contentInner: {
                    position: "absolute",
                    top: "30%",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                },

                image: {
                    position: "relative"
                }
            }
        },

        //-------------Widgets-------------
        widgets: {

            Textarea: {
                textWrap: {
                    maxWidth: "unset",
                    padding: "1em"
                },

                ulLi: {
                    listStyleType: "disc"
                }
            },

            logo: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    //justifyContent: "center",
                    marginTop: "1em",
                    marginBottom: "1em",

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                        flexDirection: "column",
                        textAlign: "center"
                    },

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                        flexDirection: "column"
                    }
                },

                image: {
                    maxWidth: "50%"
                },
                content: {
                    maxWidth: "100%"
                }
            },
            image: {
                root: {
                    position: "relative",
                    zIndex: 1
                },

                multipliers: multiplierClasses
            },
            imageWithText: {
                root: {
                    position: "relative"
                },

                textContent: {
                    position: "absolute",
                    top: 0,
                    zIndex: 3,
                    left: 0,
                    right: 0
                }
            },
            actionButton: {
                root: {
                    display: "inline-block",
                    margin: 0,
                    width: "12em",
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                    borderRadius: 0,
                    textAlign: "center",
                    fontSize: `${fontSizes.baseSize_10}rem`,
                    transition: "all ease-in-out .2s"
                },

                rootHover: {
                    backgroundColor: Utilities.Jss.lightenDarkenColor(colors.primary, 30)
                },

                link: {
                    color: colors.white,
                    textDecoration: "none",
                    padding: "1.2em",
                    display: "inline-block"
                },

                paragraph: {
                    padding: 0,
                    margin: 0
                },

                motives: {
                    white: {
                        root: {
                            backgroundColor: colors.white
                        },
                        link: {
                            color: colors.primary
                        },
                        linkHover: {
                            color: colors.white
                        }
                    },
                    black: {},
                    primary: {},
                    secondary: {},
                    tertiary: {}
                },
            },

            socialIcon: {
                root: {
                    padding: "0.5em"
                },

                icon: {
                    fontSize: "1.5em"
                }
            },

            contactForm: {
                root: {

                },

                input: {
                    width: "28em",
                    padding: "2em",
                    outline: "none",
                    border: `2px solid ${colors.primary}`,
                    borderRadius: 0,

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.md)]: {
                        width: "100%"
                    },

                    [Utilities.Jss.mediaQueryMaxWidth(grid.breakpoints.sm)]: {
                        width: "auto"
                    }
                },

                inputFocused: {
                    boxShadow: "none"
                },

                inputError: {
                    borderColor: colors.error
                },

                textarea: {
                    resize: "none"
                },

                labelError: {
                  color: colors.error
                },

                buttonWrap: {
                    textAlign: "right"
                }
            }
        }
    }
}

export const DefaultTheme = theme;