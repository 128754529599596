import {Route, Router as ReactRouter, Switch} from "react-router-dom";
import history from "../helpers/history";
import {PrivateRoute} from "../components/private-route/private-route";
import Pages from "../pages/pages/pages";
import PageSettings from "../pages/page-settings";
import MyProjects from "../pages/projects/projects";
import {LoginPage} from "../pages/login/login";
import React from "react";
import {CmsModule} from "../modules/cms";
import {ProjectError} from "../pages/errors/project-error";
import {Constants} from "../constants";

export const Router = () => {
    return (
        <ReactRouter history={history}>
            <Switch>
                <PrivateRoute exact path={Constants.Routes.MyPages} component={Pages}/>

                <PrivateRoute exact path={Constants.Routes.PageSettings} component={PageSettings}/>

                <PrivateRoute exact path={Constants.Routes.MyProjects} component={MyProjects}/>

                <PrivateRoute exact path={Constants.Routes.Homepage} component={CmsModule}/>

                <Route path={Constants.Routes.Errors.ProjectError} component={ProjectError}/>

                <Route path={Constants.Routes.Login} component={LoginPage}/>
            </Switch>

        </ReactRouter>
    );
}