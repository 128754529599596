import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.default;

    return {
        root: {
            textAlign: currentTheme.textAlign,
        },
        content: {
            textAlign: currentTheme.content.textAlign
        }
    }

});

export default classes;

