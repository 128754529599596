import React from 'react';
import Button from "react-bootstrap/Button";
import {Modal} from "../modal/modal";

export default ({isOpen, onSuccess, onReject, onClose}) => {
    return (
        <div className={"modal--component"}>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <div>
                    Opravdu si přejete obsah odstranit?
                </div>
                <div>
                    <Button variant={"primary"} onClick={onSuccess}>Ano</Button>
                    <Button onClick={onReject} variant="secondary">Ne</Button>
                </div>

            </Modal>
        </div>
    );
}