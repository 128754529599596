import React from "react";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {CreateTheme} from "hooks/theme";
import useStyles from "./single-text.styles";
import {Textarea} from "components/widgets/textarea/textarea";

export const SingleText = ({data, sectionIndex, customContents = null}) => {

    CreateTheme(useStyles);

    let contents = customContents || [
        {position: 1, value: Constants.Strings.TEXT_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);


    return <Textarea data={data} customContents={contents.filter(s => s.position === 1)} sectionIndex={sectionIndex} simplyMode={true} />
};