import React from "react";
import {CreateTheme} from "hooks/theme";
import useStyles from "./separator.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {Utilities} from "utilities/utilities";

export const Separator = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);
    return (
        <div className={`separator--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section} >
                <div className={classes.root} />
            </ObjectActions>
        </div>
    );
}