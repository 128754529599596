import {defaultConfig, DefaultTheme} from "templates/default/default";
import {objectDeepCopy} from "protopytes/clone";
import {HaasmontBase} from "templates/haasmont/base";

const haasmontConfig = objectDeepCopy(defaultConfig);

haasmontConfig.colors.primary = "#e57b76";
haasmontConfig.colors.secondary = "#f6f6f6";
haasmontConfig.colors.tertialy = "#938c7a";

const haasmontTheme = objectDeepCopy(DefaultTheme(haasmontConfig));

HaasmontBase(haasmontTheme, haasmontConfig);

export const HaasmontProtipozarniOchranaTheme = haasmontTheme;



