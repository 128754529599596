import {useTheme} from "react-jss";
import {useSelector} from "react-redux";
import {Constants} from "../constants";

export const CreateTheme = (styleCreator, disableOnSSR = false) => {
    try {
        const themeStore = useSelector(s => s.theme);

        const mode = useSelector(s => s.mode);

        const theme = useTheme();

        //For ssr disable CMS App components and pages
        if(!disableOnSSR && mode === Constants.Mode.CMS) {
            return styleCreator({...themeStore, theme});
        }

        return styleCreator();

    } catch (e) {
        throw new Error("Property is probably missing in theme config. \nInner exception: " + e.message);
    }
};