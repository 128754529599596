import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.section;

    return {
        root: {
            ...currentTheme.root,
            "&:hover $icon": currentTheme.rootHoverIcon
        },

        icon: {
            ...currentTheme.icon,
           /* "&:hover": currentTheme.iconHover*/
        },

        editBlock: currentTheme.editBlock
    }
});

export default classes;

