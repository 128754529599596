import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateWidgetContents} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {CreateTheme} from "hooks/theme";
import {ObjectActions} from "components/object-actions/object-actions";
import {Utilities} from "utilities/utilities";
import {Button, Col, Form, Row} from "react-bootstrap";
import useStyles from "./contact-form.styles";

export const ContactForm = ({data, sectionIndex, customContents = null}) => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    const target = useRef(null);

    const inputClickHandle = (e) => {
        if (e.stopPropagation) e.stopPropagation();
    };

    const updateWidgetHandle = (valuePosition) => {
        dispatch(updateWidgetContents(target.current.value, data, project, valuePosition, sectionIndex))
    };

    let contents = customContents || [
        {position: 1, value: Constants.Strings.FORM_EMAIL_TO}
    ];

    fillWidgetContents(data, contents);

    const ChildActions = () => (
        <>
            <Form.Control type={"email"} ref={target} onClick={inputClickHandle}
                          placeholder={"E-mail příjemce zprávy..."} required={true}/>
            <Button onClick={() => updateWidgetHandle(contents[0].position)} variant={"secondary"}>Hotovo</Button>
        </>
    );

    return (
        <div className={`contact-form--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <div className={"d-inline-block"}>
                <ObjectActions
                    sectionIndex={sectionIndex}
                    object={data}
                    type={Constants.ObjectTypes.WIDGET}
                    childActions={<ChildActions/>}
                >
                    <div className={classes.root}>

                        <form action={"/email/send"} method={"post"}>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            className={classes.input} name={"email"} type="email"
                                            placeholder="Email"
                                            data-rule-required="true"
                                            data-rule-email="true"
                                            data-msg-required="E-mail je povinný"
                                            data-msg-email="Tento e-mail je bohužel neplatný"
                                            aria-required="true"/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            as={"textarea"} rows={4} className={classes.textarea}
                                            name={"message"} type="text" placeholder="Obsah zprávy"
                                            data-rule-required="true"
                                            data-msg-required="Zpráva je povinná"
                                            aria-required="true"/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={classes.buttonWrap}>
                                        <input name={"mailto"} type={"hidden"} value={contents[0].value}/>
                                        <Button type={"submit"} className={classes.button}>Odeslat</Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </ObjectActions>
            </div>
        </div>
    )
};