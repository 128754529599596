import {Link} from "react-router-dom";
import React from "react";
import {FaHome} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./navigation.styles";

export default () => {

    const classes = CreateTheme(useStyles, true);

    return (
        <div className={"navigation--component"}>
            <nav className={classes.root}>
                <ul className={classes.list}>
                    <li>
                        <Link className={classes.linkActive} to="/"><FaHome
                            className={classes.icon}/></Link>
                    </li>
                    <li>
                        <Link className={classes.link} to="/my-projects">Moje projekty</Link>
                    </li>
                    <li>
                        <Link className={classes.link} to="/my-pages">Stránky</Link>
                    </li>
                    <li>
                        <Link className={classes.link} to="/page-settings">Nastavení</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}