import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.pageAttributeForm;

    return {
       /* textField: {
            ...currentTheme.textField,
            "&:focus": currentTheme.textFieldFocus
        },

        button: {
            ...currentTheme.button,
            "&:hover": currentTheme.buttonHover
        },

        textArea: {
            extend: "textField",
            ...currentTheme.textArea
        },*/
        label: currentTheme.label
    }
});

export default classes;

