import React from 'react';
import {useSelector} from "react-redux";
import {ThemeProvider} from "react-jss";
import AppWithTheme from "components/app/app-theme";
import Modal from 'react-modal';

Modal.setAppElement('#root');

const App = () => {
    const themeStore = useSelector(s => s.theme);


    return (
        <>
            <ThemeProvider theme={themeStore}>
                <AppWithTheme />
            </ThemeProvider>
        </>
    );
};

export default App;
