import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.widgets.contactForm;

    return {
        root: currentTheme.root,
        input: {
            ...currentTheme.input,
            "&.error": currentTheme.inputError,
            "& + .error": currentTheme.labelError,
            "&:focus": currentTheme.inputFocused
        },
        textarea: {
            extend: "input",
            ...currentTheme.textarea,
        },

        buttonWrap: currentTheme.buttonWrap,
        button: theme.widgets.actionButton.root
    }
});

export default classes;

