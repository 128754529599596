import {ImageService} from "services/image-service";
import {loadImagesSuccess} from "store/images/actions/actions";
import {setLoader} from "store/loader/action-creator";

const service = new ImageService();

export const uploadImages = (userId, projectId, imagesToUpload) => {
    return async (dispatch) => {
        const images = await service.uploadImages(userId, projectId, imagesToUpload);
        dispatch(loadImagesSuccess(images));
    }
}

export const deleteImage = (userId, projectId, imageId) => {
    return async (dispatch) => {
        await dispatch(setLoader(true));
        const images = await service.deleteImage(userId, projectId, imageId);
        dispatch(loadImagesSuccess(images));
        await dispatch(setLoader(false));
    }
}

export const addAttributesToImage = (attributes, imageId, userId, projectId) => {
    return async (dispatch) => {
        dispatch(setLoader(true));

        const images = await service.addAttributes(attributes, imageId, userId, projectId);

        dispatch(setLoader(false));
        dispatch(loadImagesSuccess(images));
    }
}