import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.objectAdder;

    return {
        root: {
            ...currentTheme.root,
            ...theme.components.sectionAdder.root,
            "&:hover $content": currentTheme.contentActive
        },
        rootActive: {
            extend: "root"
        },
        rootStatic: {
            ...currentTheme.rootStatic,
            "& $content": currentTheme.contentActive
        },
        content: {
            ...currentTheme.content,
            justifyContent: "center"
        },
        icon: currentTheme.icon
    }
});

export default classes;

