import React, {useRef, useState} from "react";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {Container} from "react-bootstrap"
import {ObjectActions} from "components/object-actions/object-actions";
import {Popover} from "components/popover/popover";
import {BackgroundColors} from "components/background-colors/background-colors";
import {FaPalette} from "react-icons/fa";
import {Utilities} from "utilities/utilities";
import {fillImageContents} from "../sections";
import {useSelector} from "react-redux";

export const FluidContainer = ({section, sectionIndex}) => {

    const allImages = useSelector(s => s.images);

    const target = useRef(null);

    const [showColors, setShowColors] = useState(false);

    const getSectionStyle = () => {
        const imageContents = [];
        let style = {};

        fillImageContents(allImages, section.images, imageContents);

        if(imageContents.length > 0) {
            const {folder, filename} = imageContents[0].value;
            style = {
                backgroundImage: `url("https://res.cloudinary.com/iwsolutions/image/upload/f_auto,q_auto,c_lfill,w_1920,h_800/${folder}/${filename}")`,
                backgroundSize: "cover"
            }

            return style;
        }
    }

    const ChildActions = () => (
        <>
            <div onClick={() => setShowColors(true)}>
                <FaPalette className={"icon"}/>
            </div>
        </>
    );

    const id = "container__section-index-" + sectionIndex;

    return (
        <div style={getSectionStyle()} className={`fluid-container--sections ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section} childActions={<ChildActions/>}>
                <Container fluid={true} className={"p-0"}>
                    <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={1}/>
                </Container>
            </ObjectActions>

            <Popover placement={"top"} title={"Barva pozadí"} isOpen={showColors} target={target} id={id}
                     onClickOutside={() => setShowColors(false)}>
                <BackgroundColors id={id} target={target} show={setShowColors} sectionIndex={sectionIndex}/>
            </Popover>

        </div>
    );
};