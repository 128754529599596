import {HaasmontTheme} from "templates/haasmont/haasmont";
import {DefaultTheme} from "templates/default/default";
import {HaasmontProstupyTheme} from "templates/haasmont/protipozarni-prostupy";
import {HaasmontJadroveVrtaniTheme} from "./haasmont/jadrove-vrtani";
import {HaasmontProtipozarniDvereTheme} from "./haasmont/protipozarni-dvere";
import {ItCaveTheme} from "./itcave/web";
import {HaasmontProtipozarniOchranaTheme} from "templates/haasmont/protipozarni-ochrana";
import {HaasmontTechnologickeCisteniTheme} from "templates/haasmont/technologicke-cisteni";

export const Themes = {
    "default": DefaultTheme,
    "haasmont": HaasmontTheme,
    "haasmontProstupy": HaasmontProstupyTheme,
    "haasmontJadroveVrtani": HaasmontJadroveVrtaniTheme,
    "haasmontProtipozarniDvere": HaasmontProtipozarniDvereTheme,
    "haasmontProtipozarniOchrana": HaasmontProtipozarniOchranaTheme,
    "haasmontTechnologickeCisteni": HaasmontTechnologickeCisteniTheme,
    "itcaveWeb": ItCaveTheme
};