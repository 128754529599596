import React, {useEffect, useLayoutEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {ProjectService} from "services/project-service";
import {CreateTheme} from "hooks/theme";
import useStyles from "./projects.styles";
import {Utilities} from "utilities/utilities";
import history from "../../helpers/history";
import {loggedUserGetter} from "../../helpers/logged-user-getter";
import {Constants} from "../../constants";
import Select from "react-select";
import {SelectBoxItemMapper} from "../../helpers/select-box-item-mapper";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/loader/action-creator";
import {store} from "react-notifications-component";
import {NotificationFactory} from "../../components/notification/notification";

const projectService = new ProjectService();

export default () => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const [projects,  setProjects]= useState([]);

    const [selectedProject,  setSelectedProject]= useState();

    React.useEffect = Utilities.SSR.canUseDOM() ? useEffect : useLayoutEffect;

    const projectsAsListItems = SelectBoxItemMapper(projects.map(s => ({ name : s.name, value:s.id })));

    useEffect(() => {
        const getData = async () => {
            const user = loggedUserGetter();

            const projects = await projectService.getAllProjects(user.id);
            setProjects(projects);
        };

        getData();

    }, []);

    const handleSelectProject = (e, projectId) => {
        localStorage.setItem("iws.cms.frontend.selectedProject", projectId);

        history.push(Constants.Routes.Homepage);
    }

    const handleRestoreFromHandle = async (currentProject) => {
        var user = loggedUserGetter();

        if(user !== undefined) {

            dispatch(setLoader(true));

            var loadedProject = await projectService.getProjectById(user.id, selectedProject.value);
            loadedProject.id = currentProject.id;
            loadedProject.name = currentProject.name;
            loadedProject.templateName = currentProject.templateName;

            //TODO: HOTFIX: Při updatu CMS SDK přidat Id stránky do mapper ignoru
            loadedProject.pages.map(s => s.id = 0);

            await projectService.saveProject(user.id, loadedProject);

            dispatch(setLoader(false));

            store.addNotification(NotificationFactory.create("Obnova projektu", `Projekt byl úspěšně obnoven!`, "info"));
        }
    }

    return (
        <div className={"projects-page"}>
            <Container>

                <h1>Moje projekty</h1>

                <Row>
                    {projects.map((project, index) => (
                        <Col key={index} sm={3} className={"d-flex"}>
                            <div className={classes.project}>
                                <span className={classes.projectName}>{project.name}</span>

                                <Button variant={"primary"} size={"sm"} onClick={(e) => handleSelectProject(e, project.id)}>Vybrat projekt</Button>

                                <div className={"d-flex align-items-center justify-content-center mt-5"}>
                                    <Select placeholder={"Obnovit z"} className={"w-50"}
                                            options={projectsAsListItems.filter(s => s.label !== project.name)} onChange={(selectedProject) => setSelectedProject(selectedProject)}/>
                                    <Button variant={"primary"} size={"md"} onClick={() => handleRestoreFromHandle(project)}>Obnovit</Button>
                                </div>

                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};