import React from "react";
import Container from "react-bootstrap/Container";
import {FaEnvelope, FaPhone} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./top-bar.styles";
import {SectionWidgets} from "components/section-widgets/section-widgets";
import {Utilities} from "utilities/utilities";

export const TopBar = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    return (
        <div className={`top-bar--section ${Utilities.Cms.getHiddenClasses(section)}`}>

            <div className={classes.root}>
                <Container>
                    <div className={classes.wrap}>
                        <div className={classes.phoneIcon}>
                            <FaPhone className={classes.svg}/>
                            <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={1}/>
                        </div>
                        <div className={classes.emailIcon}>
                            <FaEnvelope className={classes.svg}/>
                            <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={2}/>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};