import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.separator;

    return {
        root: currentTheme.root
    }
});

export default classes;

