export const NotificationFactory = {

    create(title, message, type) {
        return {
            className: "notification",
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            width: 400,
            dismiss: {
                duration: 4000
            }
        }
    }
};