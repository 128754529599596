import {Textarea} from "./textarea/textarea";
import {ActionButton} from "./action-button/action-button";
import {Logo} from "./logo/logo";
import {Image} from "components/widgets/image/image";
import {ImageWithText} from "components/widgets/image-with-text/image-with-text";
import {FacebookIcon} from "components/widgets/facebook-icon/facebook-icon";
import {SocialBox} from "components/widgets/social-box/social-box";
import {SingleText} from "components/widgets/single-text/single-text";
import {ContactForm} from "./contact-form/contact-form";

export const allWidgets = {
    "Textarea": Textarea,
    "SingleText": SingleText,
    "ActionButton": ActionButton,
    "Logo": Logo,
    "Image": Image,
    "ImageWithText": ImageWithText,
    "FacebookIcon": FacebookIcon,
    "SocialBox": SocialBox,
    "ContactForm": ContactForm
};