import React from "react";
import {CreateTheme} from "hooks/theme";
import useStyles from "./background-colors.styles";
import {useDispatch, useSelector} from "react-redux";
import {Utilities} from "utilities/utilities";
import {FaTintSlash} from "react-icons/fa";
import {setSectionTheme} from "store/pages/action-creator";

export const BackgroundColors = ({ sectionIndex }) => {

    const dispatch = useDispatch();

    const classes = CreateTheme(useStyles);

    const theme = useSelector(s => s.theme);

    const project = useSelector(s => s.project);

    const changeBackgroundColorHandle = (color) => {
        const newTheme = color ? {bgColor: color} : null;
        dispatch(setSectionTheme(sectionIndex, project, newTheme));
    };

    const getColors = () => {
        return Utilities.Cms.getThemeColors(theme).map(s => s.hex);
    };

    return (

        <div className={classes.root}>
            <div onClick={() => changeBackgroundColorHandle(null)} className={classes.colorItem}><FaTintSlash className={classes.svg} /></div>
            {getColors().map((color, index) => (
                <div key={index} onClick={() => changeBackgroundColorHandle(color)} className={classes.colorItem} style={{backgroundColor: color}}/>
            ))}
        </div>
    );
}