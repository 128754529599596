export const objectDeepCopy = (obj) =>
{
    if (typeof(obj)=="object")
    {
        let clone = {};
        for (let prop in obj)
            if (obj.hasOwnProperty(prop))
                clone[prop] = objectDeepCopy(obj[prop]);

        return clone;
    }
    else
        return obj;
}