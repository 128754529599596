import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import {Col, Form, Row} from "react-bootstrap";
import {loginUser, logoutUser} from "../../applications/authentication/actions/actions";
import {CreateTheme} from "../../hooks/theme";
import useStyles from "./login.styles";
import Button from "react-bootstrap/Button";

export const LoginPage = () => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const {handleSubmit, register, errors, reset} = useForm();

    useEffect(() => {
        dispatch(logoutUser());

        reset();
    }, [dispatch, reset]);

    const onSubmit = async (form, e) => {
        e.preventDefault();

        if (form.username && form.password) {
            dispatch(await loginUser(form.username, form.password));
        }

        reset();
    }

    return (
        <div className={"login--page"}>
            <div className={classes.root}>
                <span className={classes.header}>Přihlášení do administrace</span>
                <form name="form" onSubmit={handleSubmit(onSubmit)}>

                    {errors.username &&
                    <Alert variant="danger"><span><strong>Email</strong> je špatně vyplněn</span></Alert>}
                    {errors.password && <Alert variant="danger"><span><strong>Heslo</strong> je povinné</span></Alert>}

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Control className={classes.input} name={"username"} type="text"
                                              placeholder="E-mail" ref={register({
                                    required: 'Required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address"
                                    }
                                })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Control className={classes.input} name={"password"} type="password"
                                              placeholder="Heslo" ref={register({
                                    required: 'Required'
                                })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Button className={classes.button} variant={"primary"} type="submit">Přihlásit
                                    se</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );

}