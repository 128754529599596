import React, {useEffect, useLayoutEffect} from "react";
import {Route, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import CmsPage from "pages/cms/cms";
import {Utilities} from "utilities/utilities";
import {HeaderBar} from "../components/header-bar/header-bar";
import {loadCmsData} from "../applications/project/actions/actions";

export const CmsModule = () => {

    const location = useLocation();

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    React.useEffect = Utilities.SSR.canUseDOM() ? useEffect : useLayoutEffect;

    useEffect(() => {
        const getData = async () => {
            dispatch(await loadCmsData(location));
        }

        getData();

    }, [dispatch, location]);

    return (
        <div className="App">
            <HeaderBar />

            {project.pages.map((page, index) => (
                <Route key={index} path={page.url} component={() => <CmsPage/>}/>
            ))}
        </div>
    );
}