import {Utilities} from "utilities/utilities";
import {projectLoadSuccess} from "../project/actions/project-load-success";

export const addNewPage = (newPage, project) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        newPages.push(newPage);

        newProject.pages = newPages;
        dispatch(projectLoadSuccess(project))
    }
};

export const removePage = (pageId, project) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        const index = newPages.findIndex(s => s.id === pageId);

        if (index !== -1) {
            newPages.splice(index, 1);

            newProject.pages = newPages;
            dispatch(projectLoadSuccess(newProject));
        }
    }
};

export const setEditingPage = (pageId, project) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        newPages.map(s => s.isEdited = false);

        const pageIndex = newPages.findIndex(s => s.id === pageId);
        newPages[pageIndex].isEdited = true;

        newProject.pages = newPages;
        dispatch(projectLoadSuccess(project))
    }
};

export const addSection = (section, project, position = 0) => {
    return (dispatch) => {
        debugger;
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        const pageIndex = project.pages.findIndex(s => s.isEdited);

        section.widgets = [];
        section.contents = [];
        section.images = [];

        newPages[pageIndex].sections.splice(position, 0, Object.assign({}, section));

        recalculatePosition(newPages[pageIndex].sections);

        newProject.pages = newPages;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const setSectionTheme = (sectionIndex, project, theme) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        const pageIndex = project.pages.findIndex(s => s.isEdited);

        newPages[pageIndex].sections[sectionIndex].theme = theme;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const setMetadata = (project, metadata) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        const pageIndex = project.pages.findIndex(s => s.isEdited);

        newPages[pageIndex].metaData = metadata;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const deleteSection = (sectionIndex, project) => {
    return (dispatch) => {
        const newPages = getProjectPages(project);
        const newProject = getNewProject(project);

        const pageIndex = project.pages.findIndex(s => s.isEdited);

        newPages[pageIndex].sections.splice(sectionIndex, 1);

        recalculatePosition(newPages[pageIndex].sections);

        dispatch(projectLoadSuccess(newProject));
    }
};

export const setSectionHiddenBreakpoints = (hiddenBreakpoints, section, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        section.hiddenBreakpoints = hiddenBreakpoints;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const setSectionSharing = (isSharing, section, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        section.share = isSharing;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const setSectionVerticalAlignment = (alignment, section, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        section.verticalAlignment = alignment;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const setSectionHorizontalAlignment = (alignment, section, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        section.horizontalAlignment = alignment;
        dispatch(projectLoadSuccess(newProject));
    }
};

export const addWidget = (widget, project, sectionIndex, positionInSection, blockIndex) => {
    return (dispatch) => {

        const newProject = getNewProject(project);
        const currentPage = getCurrentPage(project);
        const pageSection = currentPage.sections[sectionIndex];

        const newWidget = Object.assign({}, widget);

        newWidget.positionInSection = positionInSection;
        newWidget.blockIndex = blockIndex + 1;
        newWidget.contents = [];
        newWidget.images = [];
        newWidget.motive = {};
        newWidget.percentSize = 100;

//Object.assign - because we need deep copy (without reference)
        pageSection.widgets.push(newWidget);

        recalculateWidgetPositions(pageSection, positionInSection, newWidget.blockIndex);

        dispatch(projectLoadSuccess(newProject));
    }
};

export const deleteWidget = (widget, project, sectionIndex) => {
    return (dispatch) => {
        const newProject = getNewProject(project);
        const currentPage = getCurrentPage(project);

        const section = currentPage.sections[sectionIndex];
        const widgetIndex = section.widgets.findIndex(s => s.positionInSection === widget.positionInSection && s.blockIndex === widget.blockIndex);

        if (widgetIndex >= 0) {
            section.widgets.splice(widgetIndex, 1);
            dispatch(projectLoadSuccess(newProject));
        }
    }
};

export const setWidgetHiddenBreakpoints = (hiddenBreakpoints, widget, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        widget.hiddenBreakpoints = hiddenBreakpoints;

        dispatch(projectLoadSuccess(newProject));
    }
};

export const setWidgetPercentSize = (percentSize, widget, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        widget.percentSize = percentSize;

        dispatch(projectLoadSuccess(newProject))
    }
};

//Update content value in section
export const updateSectionContents = (content, project, valuePosition, sectionIndex) => {
    return (dispatch) => {
        const newProject = getNewProject(project);
        const newPages = getProjectPages(project);
        const currentPage = getCurrentPage(project);

        updateContent(newPages, currentPage.sections[sectionIndex], valuePosition, content);

        dispatch(projectLoadSuccess(newProject));
    }
};

export const updateSectionImages = (selectedImageId, project, positionInSection, sectionIndex) => {
    return (dispatch) => {
        const newProject = getNewProject(project);
        const currentPage = getCurrentPage(project);
        const currentSection = currentPage.sections[sectionIndex];

        updateImages(selectedImageId, currentSection, positionInSection);

        dispatch(projectLoadSuccess(newProject));
    }
}


//Update content values in current Content
export const updateWidgetContents = (content, widget, project, valuePosition) => {
    return (dispatch) => {
        const newProject = getNewProject(project);
        const newPages = getProjectPages(project);

        updateContent(newPages, widget, valuePosition, content);
        dispatch(projectLoadSuccess(newProject));

    }
};

export const updateWidgetImages = (selectedImageId, widget, project, positionInWidget) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        updateImages(selectedImageId, widget, positionInWidget);

        dispatch(projectLoadSuccess(newProject));
    }
};

export const setWidgetMotive = (motive, widget, project) => {
    return (dispatch) => {
        const newProject = getNewProject(project);

        widget.motive = !motive.name ? {} : {name: motive.name};

        dispatch(projectLoadSuccess(newProject));
    }
};

const updateContent = (newPages, currentObject, position, content) => {
    if (!currentObject.contents.some(s => s.position === position)) {
        currentObject.contents.push({
            position: position,
            value: content
        });
    } else {
        const index = currentObject.contents.findIndex(s => s.position === position);
        currentObject.contents[index].value = content;
    }

    return currentObject;
}

const updateImages = (imageId, currentObject, positionInWidget) => {
    const imageIndex = currentObject.images.findIndex(s => s.position === positionInWidget);

    if (imageIndex >= 0) {
        currentObject.images[imageIndex] = {
            imageId: imageId,
            position: positionInWidget
        };
    } else {
        currentObject.images.push(
            {
                imageId: imageId,
                position: positionInWidget
            }
        );
    }
}

const getCurrentPage = (project) => {
    return Utilities.Cms.getCurrentEditedPage(project.pages)
}

const getProjectPages = (project) => {
    const newProject = getNewProject(project);
    return [...newProject.pages];
}

const getNewProject = (project) => {
    return {...project}
}

const recalculatePosition = (sections) => {
    sections.forEach((section, index) => {
        section.position = index;
    });
}

const recalculateWidgetPositions = (section, positionInSection, currentBlockIndex) => {
    //seřadíme pouze widgety v konkrétní positionInSection

    const widgetsInSectionPosition = section.widgets
        .filter(s => s.positionInSection === positionInSection);

    widgetsInSectionPosition.forEach((widget, index) => {

        const lastIndex = widgetsInSectionPosition.length - 1;

        //vyhodit sebe samotného a posunujeme pouze ty, které jsou indexem za nově přidanou položkou
        if (lastIndex !== index && currentBlockIndex <= widget.blockIndex) {
            widget.blockIndex++;
        }
    });
}
