import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components;

    return {
        root: currentTheme.objectActions,
        select: currentTheme.selectBox.root,
        closeIcon: currentTheme.objectActions.closeIcon
    }
});

export default classes;

