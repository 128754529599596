import {FaTimes} from "react-icons/fa";
import React from "react";
import {CreateTheme} from "../../hooks/theme";
import useStyles from "./modal.styles";
import ReactModal from 'react-modal';

export const Modal = ({open, onClose, children}) => {

    const classes = CreateTheme(useStyles, true);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    return (
        <ReactModal
            className={classes.root}
            overlayClassName={classes.overlay}
            isOpen={open}
            style={customStyles}
        >
            <div className={classes.body}>
                <FaTimes className={classes.closeIcon} onClick={onClose}/>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </ReactModal>
    );
}