import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components;

    return {
        select: currentTheme.selectBox.root
    }
});

export default classes;

