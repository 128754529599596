import React, {useRef, useState} from "react";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {CreateTheme} from "hooks/theme";
import useStyles from "./two-cols.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaPalette} from "react-icons/fa";
import {BackgroundColors} from "components/background-colors/background-colors";
import {Popover} from "components/popover/popover";
import {Utilities} from "utilities/utilities";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export const TwoColsSection = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    const target = useRef(null);

    const [showColors, setShowColors] = useState(false);

    const ChildActions = () => (
        <>
            <div onClick={() => setShowColors(true)}>
                <FaPalette className={"icon"}/>
            </div>
        </>
    );

    const id = "two-cols__section-index-" + sectionIndex;

    return (
        <div className={`two-cols--section ${Utilities.Cms.getHiddenClasses(section)}`}>

            <ObjectActions sectionIndex={sectionIndex} object={section} childActions={<ChildActions/>}>

                <div ref={target} id={id} className={classes.root}>
                    <div className={classes.colWrap}>
                        <div className={classes.col}>
                            <div className={"content-wrap"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={1}/>
                            </div>
                        </div>

                        <div className={classes.col}>
                            <div className={"content-wrap"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={2}/>
                            </div>
                        </div>
                    </div>

                    <Container>
                        <Row className={"align-items-center"}>
                            <Col lg md={12} sm={12} className={"bs-col"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={3}/>
                            </Col>

                            <Col lg md={12} sm={12} className={"bs-col"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={4}/>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </ObjectActions>

            <Popover placement={"top"} title={"Barva pozadí"} isOpen={showColors} target={target} id={id}
                     onClickOutside={() => setShowColors(false)}>
                <BackgroundColors id={id} target={target} show={setShowColors} sectionIndex={sectionIndex} />
            </Popover>

        </div>
    );
};