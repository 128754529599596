export const projectInitialValue = {
    pages: [],
    owner: {name: "", email: ""}
};

export const ProjectReducer = (state = projectInitialValue, action) => {
    if (action.type === 'LOAD_PROJECT') {
        return action.payload;
    }

    return state
};