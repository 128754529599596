import React from "react";
import {Section} from "components/section/section";
import {Constants} from "../../constants";
import {SectionAdder} from "components/section-adder/section-adder";
import {useSelector} from "react-redux";
import {Utilities} from "utilities/utilities";
import {CreateTheme} from "hooks/theme";
import useStyles from "./cms.style";

export default () => {

    const classes = CreateTheme(useStyles, true);

    const project = useSelector(s => s.project);
    const mode = useSelector(s => s.mode);
    const viewer = useSelector(s => s.viewer);

    let sections = [];

    if(project.pages.length > 0) {
        const currentPage = Utilities.Cms.getCurrentEditedPage(project.pages);
        sections = (currentPage && currentPage.sections) || [];
    }

    /**
     * Get App
     * @returns {*}
     */
    return (
        <div className="cms--page">
            <div className={classes.sections}>

                {sections.sort((a, b) => a.position - b.position).map((section, index = 2) => (
                    <div className={"section-wrapper"} key={index} id={`section-${section.componentName}-${index}`}>
                        <Section
                            key={index}
                            index={index}
                            section={section}
                            lastInSections={index === (sections.length -1)}
                        />

                        {(mode === Constants.Mode.CMS && !viewer) ?
                            <SectionAdder position={index}/> : ""}
                    </div>
                ))}

                {sections.length <= 0 && mode === Constants.Mode.CMS ?
                    <SectionAdder position={1} withoutSections={true}/>
                    : ""}
            </div>
        </div>
    )
}