import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateWidgetContents} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaFacebookF} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./facebook-icon.styles";
import Button from "react-bootstrap/Button";
import {Utilities} from "utilities/utilities";

export const FacebookIcon = ({data, sectionIndex, customContents = null}) => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    const target = useRef(null);

    const updateWidgetHandle = (valuePosition) => {
        dispatch(updateWidgetContents(target.current.value, data, project, valuePosition, sectionIndex))
    };

    const inputClickHandle = (e) => {
        if(e.stopPropagation) e.stopPropagation();
    };

    let contents = customContents || [
        {position: 1, value: Constants.Strings.LINK_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);

    const ChildActions = () => (
        <>
            <div>
                <input defaultValue={contents[0].value} ref={target} onClick={inputClickHandle} placeholder={"Odkaz na Váš facebook"} />
                <Button onClick={() => updateWidgetHandle(contents[0].position)} variant={"secondary"}>Hotovo</Button>
            </div>
        </>
    );

    return (
        <div className={`facebook-icon--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
        <ObjectActions
            sectionIndex={sectionIndex}
            object={data}
            type={Constants.ObjectTypes.WIDGET}
            childActions={<ChildActions/>}
        >

            <div className={classes.root}>
                <a href={contents[0].value} title={"Přejít na Facebook"}><FaFacebookF className={classes.icon} /></a>
            </div>

        </ObjectActions>

        </div>
    )
};