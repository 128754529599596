import {
    authLogoutSuccess,
    authRequest,
    authRequestFail,
    authRequestSuccess
} from "../../../../store/authentication/actions/authentication-actions";
import {clientUserLogin} from "../../../../helpers/client-user-login";
import {clientUserLogout} from "../../../../helpers/client-user-logout";
import {Constants} from "../../../../constants";

export const createLoginAction = (deps) =>
    (username, password) => //ThunkAction
        async (dispatch) => { //Promise return dispatch => {

            dispatch(authRequest({username}));

             const response = await deps.userService.login(username, password);

             if(response.status !== 200) {
                 dispatch(authRequestFail(response.data));
             }
             else {
                 clientUserLogin(response.data);

                 dispatch(authRequestSuccess(response.data));
                 document.location.replace(Constants.Routes.MyProjects);
             }
        };

export const createlogoutAction = (deps) =>
    () => //ThunkAction
        (dispatch) => { //Promise return dispatch => {

            clientUserLogout();
            dispatch(authLogoutSuccess());
        }
