import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addWidget} from "store/pages/action-creator";
import {FaPlus} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./widget-adder.styles";
import {Popover} from "components/popover/popover";
import {Button} from "react-bootstrap";

/**
 * SectionPosition = pozice umístění widgetu pro případ, že má možnost být na více místech v sekci.
 *
 * Například Dva sloupce apod.
 * 1. sloupec - PositionInSection = 1, (ve sloupci 1 může být n dalších widgetů, kterým určuje pozici blockIndex, viz níže.)
 * 2. sloupec - PositionInSection = 2
 *
 * BlockIndex - pozice konkrétního widgetu v PositionInSection
 *
 * Příklad:
 * positionInSection = 1
 *  - widget - blockIndex 1
 *  - widget - blockIndex 2
 *
 * positionInSection = 2
 *  - widget - blockIndex 1
 *  - widget - blockIndex 2
 *  - widget - blockIndex 3
 *
 * positionInSection = 3
 *  - widget - blockIndex 1
 *  - widget - blockIndex 3
 */

export const WidgetAdder = ({sectionIndex, positionInSection, blockIndex}) => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    const target = useRef(null);

    const widgets = useSelector(s => s.staticData.widgets);

    const project = useSelector(s => s.project);

    const addWidgetHandle = (e, widget) => {
        e.stopPropagation();

        dispatch(addWidget(widget, project, sectionIndex, positionInSection, blockIndex));
        setPopoverOpen(false);
    };

    const className = popoverOpen ? classes.rootActive : classes.root;

    const id = "widget-adder__section-index-" + sectionIndex;

    return (
        <div ref={target} id={id} className={className} onClick={toggle}>

           {/* <div className={classes.content} onClick={toggle}>
                <div className={classes.stroke}>
                    <FaCube className={classes.icon} />
                </div>

            </div>*/}

            <div className={"d-flex justify-content-center"}>
                <Button onClick={toggle} variant={"success"} className={`d-flex align-items-center ${classes.button}`}>
                    <FaPlus className={classes.icon} />
                </Button>
            </div>

            <Popover isOpen={popoverOpen} id={id} target={target} onClickOutside={() => setPopoverOpen(false)}>
                {widgets.map((widget, index) => (
                    <div className={"popover-content"} key={index}
                         onClick={(e) => addWidgetHandle(e, widget)}>{widget.name}</div>
                ))}
            </Popover>
        </div>
    );
};