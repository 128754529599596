import {applyMiddleware, compose, createStore} from "redux";
import reducer from "store/reducers";
import thunk from "redux-thunk";

let instance = null;

export class StoreFactory {
    constructor() {
        if (!instance) {
            instance = this;

            const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

            this.store = createStore(reducer, composeEnhancers(
                applyMiddleware(thunk)
            ));

            //this.store = createStore(reducer, applyMiddleware(thunk));
        }

        return instance;
    }

    getStore() {
        return this.store;
    }
}