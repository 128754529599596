import React from "react";
import PageAttributesForm from "components/page-attributes-form/page-attributes-form";
import {store} from "react-notifications-component";
import {NotificationFactory} from "components/notification/notification";
import {useDispatch, useSelector} from "react-redux";
import {Utilities} from "../utilities/utilities";
import {setMetadata} from "store/pages/action-creator";
import {saveProject} from "applications/project/actions/actions";

export default () => {
    const dispatch = useDispatch();

    const project = useSelector(s => s.project);
    const theme = useSelector(s => s.theme);

    let currentPage = Utilities.Cms.getCurrentEditedPage(project.pages) || {
        metaData: {
            title: "",
            description: "",
            keywords: ""
        }
    };

    const savePageSettingsHandle = async (data) => {
        dispatch(setMetadata(project, data));

        dispatch(await saveProject(project, theme));

        store.addNotification(NotificationFactory.create("Nastavení stránky", "Stránka byla uložena", "info"));
    };

    return (
        <div className={"container"}>
            <h1>Nastavení stránky</h1>

            <PageAttributesForm onSubmit={savePageSettingsHandle} attributes={currentPage.metaData}/>
        </div>
    );
};