export const pages = {
    projects: {
        project: {
            width: "100%",
            border: "1px solid #000",
            padding: "6em 0",
            textAlign: "center"
        },

        projectName: {
            fontSize: "2em",
            display: "block",
            marginBottom: "1em"
        }
    },

    pages: {

        page: {
            marginTop: "1em"
        },


        headline: {
            marginTop: "2em",
            backgroundColor: "#d4d4d4",
            fontWeight: "bold"
        },

        actionIcon: {
            cursor: "pointer",
            marginLeft: "0.5em",
            textAlign: "center"
        },

        col: {
            display: "flex",
            justifyContent: "center"
        },

        submit: {
            marginTop: "2em"
        },

        header: {
            marginTop: "2em"
        },

        urlGenerator: {
            display: "flex",
            alignItems: "center"
        }
    },

    login: {
        root: {
            maxWidth: "25em",
            margin: "25vh auto",
            border: "1px solid #ccc",
            textAlign: "center",
            padding: "1.5em",
            backgroundColor: "#eee"
        },

        header: {
            margin: "0.5em 0 0.5em 0",
            fontSize: "2em",
            display: "block"
        },

        input: {
            padding: "0.7em",
            marginTop: "1em",
            border: "1px solid #ccc",
            width: "75%"
        },

        button: {
            marginTop: "1em"
        }
    },

    cms: {
        sections: {
            position: "relative"
        }
    }
};