import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.editor;

    return {
        root: {
            "& .editor-wrap": currentTheme.editorWrap,
            "& .mceContentBody": {
                backgroundColor: "red"
            }
        },

        textWrap: currentTheme.textWrap,
        textWrapSingleMode: currentTheme.textWrapSingleMode,
        textWrapButton: currentTheme.textWrapButton
    }
});

export default classes;


