import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.carousel;

    return {
        root: {
            "& .slider": currentTheme.wrap,
            "& .slider-wrapper": currentTheme.wrap
        },

        content: currentTheme.content,
        contentInner: currentTheme.contentInner
    }

});

export default classes;

