import React, {useRef, useState} from "react";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {CreateTheme} from "hooks/theme";
import useStyles from "./default.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {Container} from "react-bootstrap";
import {FaPalette} from "react-icons/fa";
import {BackgroundColors} from "components/background-colors/background-colors";
import {Popover} from "components/popover/popover";
import {Utilities} from "utilities/utilities";

export const DefaultSection = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    const target = useRef(null);

    const [showColors, setShowColors] = useState(false);

    const ChildActions = () => (
        <>
            <div onClick={() => setShowColors(true)}>
                <FaPalette className={"icon"}/>
            </div>
        </>
    );

    const id = "default__section-index-" + sectionIndex;

    return (
        <div className={`default--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <div className={`${Utilities.Cms.getAlignmentClasses(section)}`}>
                <ObjectActions sectionIndex={sectionIndex} object={section} childActions={<ChildActions/>}>
                    <Container>
                        <div className={classes.root}>
                            <div className={"content-wrap"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={1}/>
                            </div>
                        </div>
                    </Container>
                </ObjectActions>
            </div>

            <Popover placement={"top"} title={"Barva pozadí"} isOpen={showColors} target={target} id={id}
                     onClickOutside={() => setShowColors(false)}>
                <BackgroundColors id={id} target={target} show={setShowColors} sectionIndex={sectionIndex}/>
            </Popover>

        </div>
    );
}