import {createSaveProjectAction} from "applications/project/actions/thunks/save-data-thunk-action";
import {ProjectService} from "services/project-service";
import {SheetsRegistry} from "react-jss";
import {createLoadProjectAction} from "./thunks/load-project-thunk-action";
import {ImageService} from "../../../services/image-service";
import {StaticContentService} from "../../../services/static-content-service";

const projectService = new ProjectService();
const imageService = new ImageService();
const staticContentService = new StaticContentService();

const sheets = new SheetsRegistry();

export const saveProject = createSaveProjectAction({
    projectService: projectService,
    sheets:sheets
});

export const loadCmsData = createLoadProjectAction({
    projectService: projectService,
    imageService: imageService,
    staticContentService: staticContentService
})