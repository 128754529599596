import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.headerBar;

    return {
        root: currentTheme.root,
        changeView: currentTheme.changeView,
        header: currentTheme.header,
        icon: currentTheme.icon,
        mode: currentTheme.mode,
        rightSide: currentTheme.rightSide,
        saveChanges: currentTheme.saveChangesButton
    }
});

export default classes;

