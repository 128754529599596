import {Cloudinary} from "utilities/cloudinary";
import {useEffect, useRef} from "react";


export const Utilities = {

    Cms: {
        getCurrentEditedPage: (pages) => {
            return pages.find(s => s.isEdited);
        },

        getThemeColors: (theme) => {
            const arrFromObject = Object.values(theme.editor.colors)
                //Exclude system colors. Alerts etc.
                .filter(s => s.name !== "" && s.name !== "error" && s.name !== "warning" && s.name !== "success" && !s.name.includes("gray"));

            return [...new Map(arrFromObject.map(item => [item["hex"], item])).values()];
        },

        getThemeColorsWithAlerts: (theme) => {
            const arrFromObject = Object.values(theme.editor.colors);
            return [...new Map(arrFromObject.map(item => [item["hex"], item])).values()];
        },

        getMotiveClassName: (motive, classes) => {
            if (!motive || !motive.name) return "";

            const nameCapitalized = motive.name.charAt(0).toUpperCase() + motive.name.slice(1);
            return classes[`motive${nameCapitalized}`];
        },

        getHiddenClasses: (section) => {

            const breakpoints = section.hiddenBreakpoints;

            let classes = [];

            if (breakpoints.length <= 0) {
                return "";
            }

            classes.push(breakpoints.includes("xs") ? "d-none" : "d-block");

            classes.push(breakpoints.includes("sm") ? "d-sm-none" : "d-sm-block");

            classes.push(breakpoints.includes("md") ? "d-md-none" : "d-md-block");

            classes.push(breakpoints.includes("lg") ? "d-lg-none" : "d-lg-block");

            classes.push(breakpoints.includes("xl") ? "d-xl-none" : "d-xl-block");

            return classes.join(" ");
        },

        getAlignmentClasses: (section) => {
            const verticalAlignment = section.verticalAlignment;

            if (verticalAlignment === null) {
                return "";
            }

            return (
                (verticalAlignment.includes("baseline") && "d-flex align-items-baseline") ||
                (verticalAlignment.includes("top") && "d-flex align-items-start") ||
                (verticalAlignment.includes("center") && "d-flex align-items-center") ||
                (verticalAlignment.includes("bottom") && "d-flex align-items-end")
            );
        },

        getHorizontalAlignmentClasses: (object) => {
            const horizontalAlignment = object.horizontalAlignment;

            if (horizontalAlignment === null) {
                return "";
            }

            return (
                (horizontalAlignment.includes("start") && "text-left") ||
                (horizontalAlignment.includes("end") && "text-right") ||
                (horizontalAlignment.includes("center") && "text-center") ||
                (horizontalAlignment.includes("flex-middle") && "d-flex justify-content-center")
            );
        },

        useOnOutsideClick: (handleOutsideClick) => {
            const innerBorderRef = useRef();

            const onClick = event => {
                if (innerBorderRef.current && !innerBorderRef.current.contains(event.target)) {
                    handleOutsideClick();
                }
            };

            useMountEffect(() => {
                document.addEventListener("click", onClick, true);
                return () => {
                    document.removeEventListener("click", onClick, true);
                };
            });

            return {innerBorderRef};
        }
    },

    Cloudinary: Cloudinary,

    SSR: {
        canUseDOM() {
            return (
                typeof window !== 'undefined' &&
                typeof window.document !== 'undefined' &&
                typeof window.document.createElement !== 'undefined'
            )
        }
    },

    Jss: {
        /**
         * @return {string}
         */
        lightenDarkenColor(col, amt) {

            let usePound = false;

            if (col[0] === "#") {
                col = col.slice(1);
                usePound = true;
            }

            let num = parseInt(col, 16);

            let r = (num >> 16) + amt;

            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            let b = ((num >> 8) & 0x00FF) + amt;

            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            let g = (num & 0x0000FF) + amt;

            if (g > 255) g = 255;
            else if (g < 0) g = 0;

            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        },

        hexToRGB(hex) {

            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
            }
            throw new Error('Bad Hex');
        },

        mediaQueryMaxWidth(breakpoint) {
            return `@media (max-width: ${breakpoint}px)`;
        },

        mediaQueryMinWidth(breakpoint) {
            return `@media (min-width: ${breakpoint}px)`;
        }
    },

    Data: {
        groupBy(data, key) {
            const resultObject = data.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});

            return Object.entries(resultObject);
        }
    }
};

const useMountEffect = fun => useEffect(fun, []);