import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.widgets.image;

    return {
        root: currentTheme.root,
        ...currentTheme.multipliers
    };
});

export default classes;

