import React from "react";
import {Overlay} from "react-bootstrap";
import {Utilities} from "utilities/utilities";
import {CreateTheme} from "hooks/theme";
import useStyles from "./popover.styles";
import {Popover as BootstrapPopover} from "react-bootstrap";

export const Popover = ({isOpen, target, id, onClickOutside, children, title, placement}) => {

    placement = placement || "bottom";

    const classes = CreateTheme(useStyles, true);

    const {innerBorderRef} = Utilities.Cms.useOnOutsideClick(() => onClickOutside());

    return (
        <Overlay show={isOpen} target={target.current} placement="bottom">
            <BootstrapPopover id={"bs-popover-" + id} placement={placement} target={id} className={classes.root}>
                <div ref={innerBorderRef}>

                    {title ? <BootstrapPopover.Title>{title}</BootstrapPopover.Title> : ""}

                    <BootstrapPopover.Content>
                        <div className={classes.wrap}>
                            <div className={classes.contentList}>
                                {children}
                            </div>
                        </div>
                    </BootstrapPopover.Content>
                </div>
            </BootstrapPopover>
        </Overlay>
    )
};