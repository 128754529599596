import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.pages.pages;

    return {
        page: currentTheme.page,
        col: currentTheme.col,
        actionIcon: currentTheme.actionIcon,
        header: currentTheme.header,
        submit: currentTheme.submit,
        urlGenerator: currentTheme.urlGenerator,
        headline: currentTheme.headline
    }
});

export default classes;

