export class ConfigurationService {

    constructor() {
        this.loadConfig();
    }

    getConfigItem(key) {
        return this.config[key];
    }

    loadConfig() {
        if (this.config === undefined) {
            this.config = process.env;
        }
    }
}