import {Constants} from "../../../../constants";
import Select from "react-select";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CreateTheme} from "../../../../hooks/theme";
import useStyles from "./horizontal-alignment.styles";
import {SelectBoxItemMapper} from "../../../../helpers/select-box-item-mapper";
import {setSectionHorizontalAlignment} from "../../../../store/pages/action-creator";

export const HorizontalAlignmentAction = ({object, type}) => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();
    const allHorizontalAlignments = useSelector(s => s.staticData.horizontalAlignments);
    const project = useSelector(s => s.project);

    const alignments = SelectBoxItemMapper(allHorizontalAlignments);
    const selectedAlignmentForSelect = object.horizontalAlignment ? alignments.find(b => b.value === object.horizontalAlignment.value) : null;

    const alignmentSelectHandle = (data) => {
        if (type === Constants.ObjectTypes.WIDGET) {
            dispatch(setSectionHorizontalAlignment(data.value, object, project));
        }
    };

    return (
        <div className={"d-flex align-items-center"} onClick={event => event.stopPropagation()}>
            <Select placeholder={"Horizontálně"} className={classes.select} defaultValue={selectedAlignmentForSelect}
                    options={alignments} onChange={alignmentSelectHandle}/>
        </div>);

}