import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.fileUpload;

    return {
        root: currentTheme.root,
        icon: currentTheme.icon,
        uploadArea: currentTheme.uploadArea,
        uploadText: currentTheme.uploadText
    }
});

export default classes;

