import React from "react";
import {useForm} from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CreateTheme} from "hooks/theme";
import useStyles from "./page-attribute-form.styles";
import {Button, Form} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import history from "../../helpers/history";
import {Constants} from "../../constants";

export default ({attributes, onSubmit}) => {

    const classes = CreateTheme(useStyles, true);

    const {register, handleSubmit, errors} = useForm();

    return (
        <div className={"page-attributes-form--component"}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6}>
                        <label className={classes.label}>Titulek stránky</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Control type={"text"} className={classes.textField} name="title"
                                      defaultValue={attributes.title}
                                      ref={register({
                                          required: 'Required'
                                      })}
                        />
                        {errors.title &&
                        <Alert variant="danger"><span><strong>Titulek stránky</strong> je povinný</span></Alert>}
                    </Col>

                </Row>

                <br/>

                <Row>
                    <Col md={6}>
                        <label className={classes.label}>Klíčová slova</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Control type={"text"} className={classes.textField} name="keywords"
                                      defaultValue={attributes.keywords} ref={register({
                            required: 'Required'
                        })}/>
                        {errors.keywords &&
                        <Alert variant="danger"><span><strong>Klíčová slova</strong> jsou povinná</span></Alert>}
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col md={6}>
                        <label className={classes.label}>Popisek</label>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Form.Control as={"textarea"} rows="5"
                                      name="description"
                                      defaultValue={attributes.description}
                                      ref={register({
                                          required: 'Required'
                                      })}
                                      className={classes.textarea}
                        />
                        {errors.description &&
                        <Alert variant="danger"><span><strong>Popisek</strong> je povinný</span></Alert>}
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col md={3}>
                        <Button type={"button"} onClick={() => history.push(Constants.Routes.Homepage)}
                                variant={"secondary"}>{"Zpět na projekt"}</Button>
                    </Col>

                    <Col md={3} className={"text-right"}>
                        <Button variant={"info"} type="submit">Uložit změny</Button>
                    </Col>
                </Row>

            </form>
        </div>
    );
}
