import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.menu;

    return {
        root: currentTheme.root,
        list: currentTheme.list,
        link: currentTheme.link
    }

});

export default classes;

