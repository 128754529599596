import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.loader;

    return {
        root: currentTheme.root,
        inner: currentTheme.inner,
        loadingText: currentTheme.loadingText,
        svg: currentTheme.svg
    }
});

export default classes;

