import React from "react";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Image} from "components/widgets/image/image";
import {CreateTheme} from "hooks/theme";

import useStyles from "./image-with-text.styles";
import {ActionButton} from "components/widgets/action-button/action-button";
import {ObjectActions} from "components/object-actions/object-actions";
import {Textarea} from "components/widgets/textarea/textarea";
import {Utilities} from "utilities/utilities";

export const ImageWithText = ({data, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    let contents = [
        {position: 1, value: Constants.Strings.HEADER_PLACEHOLDER},
        {position: 2, value: Constants.Strings.TEXT_PLACEHOLDER},
        {position: 3, value: Constants.Strings.HEADER_PLACEHOLDER},
        {position: 4, value: Constants.Strings.TEXT_PLACEHOLDER},
        {position: 5, value: Constants.Strings.BUTTON_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);

    return (
        <div className={`image-with-text--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <ObjectActions
                sectionIndex={sectionIndex}
                object={data}
                type={Constants.ObjectTypes.WIDGET}
            >

                <div className={classes.root}>
                    <div className={classes.textContent}>
                        <Container>
                            <Row>
                                <Col lg={"auto"} md={12} sm={12}>
                                    <Textarea sectionIndex={sectionIndex} data={data}
                                                customContents={contents.filter(s => s.position === 1)}/>

                                    <Textarea sectionIndex={sectionIndex} data={data}
                                                customContents={contents.filter(s => s.position === 2)}/>

                                </Col>
                                <Col lg={"auto"} md={12} sm={12}>

                                    <Textarea sectionIndex={sectionIndex} data={data}
                                                customContents={contents.filter(s => s.position === 3)}/>

                                    <Textarea sectionIndex={sectionIndex} data={data}
                                                customContents={contents.filter(s => s.position === 4)}/>
                                </Col>
                            </Row>

                            <ActionButton sectionIndex={sectionIndex} data={data}
                                          customContents={contents.filter(s => s.position === 5)}/>

                        </Container>
                    </div>
                    <Image sectionIndex={sectionIndex} data={data}/>

                </div>
            </ObjectActions>
        </div>
    )
};