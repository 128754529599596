import {Constants} from "../../../../constants";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import {FaShareSquare} from "react-icons/fa";
import React, {useState} from "react";
import {setSectionHiddenBreakpoints, setWidgetHiddenBreakpoints} from "../../../../store/pages/action-creator";
import {useDispatch, useSelector} from "react-redux";
import {CreateTheme} from "../../../../hooks/theme";
import useStyles from "./breakpoints-action.styles";
import {SelectBoxItemMapper} from "../../../../helpers/select-box-item-mapper";

export const BreakpointsAction = ({object, type}) => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();
    const allBreakpoints = useSelector(s => s.staticData.breakpoints);
    const project = useSelector(s => s.project);

    const [selectedBreakpoints, setSelectedBreakpoints] = useState(object.hiddenBreakpoints);

    const breakpoints = SelectBoxItemMapper(allBreakpoints);
    const selectedBreakpointsForSelect = breakpoints.filter(b => selectedBreakpoints.some(k => k === b.value));

    const breakpointsSelectHandle = (data) => {
        setSelectedBreakpoints(data.map(s => s.value));
    };

    const saveBreakpoints = () => {

        if (type === Constants.ObjectTypes.SECTION) {
            dispatch(setSectionHiddenBreakpoints(selectedBreakpoints, object, project));
        } else if (type === Constants.ObjectTypes.WIDGET) {
            dispatch(setWidgetHiddenBreakpoints(selectedBreakpoints, object, project));
        }
    };

    return (
        <div className={"d-flex align-items-center"} onClick={event => event.stopPropagation()}>
            <Select placeholder={"Skrýt na"} className={classes.select} defaultValue={selectedBreakpointsForSelect}
                    options={breakpoints} onChange={breakpointsSelectHandle} isMulti/>
            <Button variant={"secondary"} onClick={saveBreakpoints}><FaShareSquare/></Button>
        </div>);

}