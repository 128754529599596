import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

   // const currentTheme = theme.widgets.Textarea;

    return {

    }

});

export default classes;

