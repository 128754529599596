import React, {useRef, useState} from "react";
import {FileUpload} from "../file-upload/file-upload";
import {Modal} from "../modal/modal"

import {useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/Button";
import {FaCog, FaTimes, FaTrash} from "react-icons/fa";
import {deleteImage} from "store/images/actions/action-creator";
import {Utilities} from "utilities/utilities";
import Img from "react-cloudinary-lazy-image";
import {CreateTheme} from "hooks/theme";
import useStyles from "./my-images.styles";
import {loggedUserGetter} from "../../helpers/logged-user-getter";
import {Constants} from "../../constants";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {addAttributesToImage} from "../../store/images/actions/action-creator";

export const MyImages = ({isOpen, onClose, onImageSelect, multipleSelection = false}) => {

    const classes = CreateTheme(useStyles, true);

    const images = useSelector(s => s.images);
    const project = useSelector(s => s.project);
    const mode = useSelector(s => s.mode);

    const ref = useRef(null);

    const dispatch = useDispatch();

    const [selectedImages, setSelectedImages] = useState([]);

    const {register, handleSubmit} = useForm();

    const selectImageHandle = () => {
        if (selectedImages.length > 0) {
            onImageSelect(selectedImages);
            onClose();
        }
    };

    const onImageClickHandle = (e, image) => {
        e.stopPropagation();

        let newImages = [];

        if (multipleSelection) {
            newImages = [...selectedImages];
        }

        newImages.push(image);
        setSelectedImages(newImages);
    };

    const imageDeleteHandle = async (image) => {
        const user = loggedUserGetter();
        await dispatch(deleteImage(user.id, project.id, image.id));
    };

    const onSubmitHandle = async (attributes, imageId) => {

        if(attributes.alt !== undefined) {
            const user = loggedUserGetter();
            await dispatch(addAttributesToImage(attributes, imageId, user.id, project.id));
        }
    }

    const CmsContent = () => (
        <Modal open={isOpen} onClose={onClose}>

            <FileUpload/>

            <div className={"my-images--component"}>
                <div className={classes.root}>
                    {images.map((image, index) => (
                        <div ref={ref} key={index}>
                            <div onClick={(e) => onImageClickHandle(e, image)}
                                 className={selectedImages.some(s => s.id === image.id) ? classes.imageWrapSelected : classes.imageWrap}>

                                <FaTrash className={classes.delete} onClick={() => imageDeleteHandle(image)}/>

                                <FormImageAttributes image={image} />

                                <Img
                                    cloudName={Utilities.Cloudinary.getCloudName()}
                                    imageName={`${image.folder}/${image.filename}`}
                                    fixed={{
                                        width: 250,
                                        height: 250
                                    }}
                                    version={`v${image.version}`}

                                    urlParams={"c_pad"}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div className={"text-right"}>
                    <Button variant="primary" onClick={selectImageHandle}>
                        Vybrat obrázek
                    </Button>
                </div>
            </div>

        </Modal>
    );

    const FormImageAttributes = ({image}) => {
        const [openAttributes, setOpenAttributes] = useState(false);

        return (
            <div onClick={event => event.stopPropagation()}>
                <FaCog className={classes.settings} onClick={() => setOpenAttributes(true)}/>

                {openAttributes ?
                    <div className={classes.attributes}>
                        <FaTimes className={classes.closeIcon} onClick={() => setOpenAttributes(false)}/>
                        <Form className={"mt-2"} onSubmit={handleSubmit((data) => onSubmitHandle(data, image.id))}>

                            <Form.Label>Alternativní text obrázku</Form.Label>
                            <Form.Control name={"alt"} type={"text"} defaultValue={image.alt} ref={register({
                                required: 'Required'
                            })}/>

                            <Button className={"d-flex mr-0 ml-auto mt-1"} type={"submit"} variant={"primary"}>Uložit</Button>
                        </Form>
                    </div> :
                    ""}
            </div>);
    }

    return mode === Constants.Mode.CMS ? <CmsContent/> : ""
}