import {Service} from "services/service";

export class ProjectService extends Service {

    async getAllProjects(userId) {
        return this.axios.post( `projects/list/${userId}`)
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }

    async getProjectById(userId, projectId) {
        return this.axios.post( `projects/${userId}/${projectId}`)
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }

    async saveProject(userId, project) {
        return await this.axios.post(`projects/save/${userId}`, project)
            .catch(Service.errorHandle)
    }
}