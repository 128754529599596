import React, {useRef, useState} from "react";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {useSelector} from "react-redux";
import {Constants} from "../../../constants";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CreateTheme} from "hooks/theme";
import useStyles from "./multi-cols.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaPalette} from "react-icons/fa";
import {Popover} from "components/popover/popover";
import {BackgroundColors} from "components/background-colors/background-colors";
import {Utilities} from "utilities/utilities";

export const MultiCols = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    const mode = useSelector(s => s.mode);

    const viewer = useSelector(s => s.viewer);

    const target = useRef(null);

    const [showColors, setShowColors] = useState(false);

    let iterator = 1;

    const incrementIterator = () => {
        iterator += 1;
    };

    const ChildActions = () => (
        <>
            <div onClick={() => setShowColors(true)}>
                <FaPalette className={"icon"}/>
            </div>
        </>
    );

    const id = "default__section-index-" + sectionIndex;

    return (
        <div className={`multi-cols--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section} childActions={<ChildActions/>}>
                <Container>
                    <Row className={`${Utilities.Cms.getAlignmentClasses(section)}`}>
                        {Utilities.Data.groupBy(section.widgets, "positionInSection").map(([key, value], index) => (

                            <Col sm={12} md={12} lg key={index} className={classes.col}>

                                <SectionWidgets section={section} sectionIndex={sectionIndex}
                                                positionInSection={iterator}/>

                                {incrementIterator()}

                            </Col>
                        ))}

                        {/* Show only for CMS !! */}
                        {(mode === Constants.Mode.CMS && !viewer) ? (
                            <Col xs className={classes.col}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex}
                                                positionInSection={iterator}/>
                            </Col>
                        ) : ""}

                    </Row>
                </Container>
            </ObjectActions>

            <Popover placement={"top"} title={"Barva pozadí"} isOpen={showColors} target={target} id={id}
                     onClickOutside={() => setShowColors(false)}>
                <BackgroundColors id={id} target={target} show={setShowColors} sectionIndex={sectionIndex}/>
            </Popover>
        </div>
    );
};