import React from "react";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {ObjectActions} from "components/object-actions/object-actions";
import {CreateTheme} from "hooks/theme";
import useStyles from "./social-box.styles";
import {FacebookIcon} from "components/widgets/facebook-icon/facebook-icon";
import {Utilities} from "utilities/utilities";

export const SocialBox = ({data, sectionIndex, customContents = null}) => {

    CreateTheme(useStyles);

    let contents = customContents || [
        {position: 1, value: Constants.Strings.LINK_PLACEHOLDER},
    ];

    fillWidgetContents(data, contents);

    return (
        <div className={`social-box--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <ObjectActions
                sectionIndex={sectionIndex}
                object={data}
                type={Constants.ObjectTypes.WIDGET}
            >

                <div className={"d-flex align-items-center"}>
                    <FacebookIcon customContents={contents.filter(s => s.position === 1)} data={data}
                                  sectionIndex={sectionIndex}/>
                    {/*<FacebookIcon customContents={contents.filter(s => s.position === 2)} data={data} sectionIndex={sectionIndex} />
                <FacebookIcon customContents={contents.filter(s => s.position === 3)} data={data} sectionIndex={sectionIndex} />*/}
                </div>

            </ObjectActions>
        </div>
    )
};