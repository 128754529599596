import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.widgets.logo;

    return {
        root: currentTheme.root,
        image: currentTheme.image,
        content: currentTheme.content
    }

});

export default classes;

