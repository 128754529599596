export const HaasmontBase = (theme, config) => {

    //headers
    theme.headers.h1.color = config.colors.primary;
    theme.headers.h1.fontSize = `${config.fontSizes.baseSize_35}em`;
    theme.headers.h1.margin = 0;
    theme.headers.h1.padding = "2em 0 0 0";

    theme.headers.h2.color = config.colors.tertialy;
    theme.headers.h2.fontSize = `${config.fontSizes.baseSize_15}em`;
    theme.headers.h2.margin = "0.5em 0 0 0";

    theme.headers.h3.color = config.colors.primary;
    theme.headers.h3.fontSize = `${config.fontSizes.baseSize_25}em`;
    theme.headers.h3.margin = "0.5em 0 0 0";

    theme.headers.h6.fontSize = "1.3em";
    theme.headers.h6.margin = 0;

//widgets
    theme.widgets.actionButton.root.borderRadius = "45px";
    theme.widgets.actionButton.root.margin = "1em 0 1em 2em";

    theme.components.editor.textWrap.padding = "0 2em 0 2em";

    theme.widgets.socialIcon.icon = {
        ...theme.widgets.socialIcon.icon,
        fill: config.colors.primary
    };

//sections
    theme.sections.twoCols.textAlign = "left";
    theme.sections.twoCols.col.textAlign = "left";

    theme.sections.threeCols.textAlign = "left";
    theme.sections.threeCols.col.textAlign = "left";

    theme.sections.multiCols.col = "left";

    theme.sections.separator.root.backgroundColor = config.colors.gray1;

    theme.widgets.imageWithText.root = {
        ...theme.widgets.imageWithText.root,
        maxHeight: "50em",
        overflow: "hidden"
    };

    theme.base.others = {

        "#section-MultiCols-0 img": {
            margin: "1em 0 1em 0"
        },

        "#section-Menu-1 img": {
            margin: "2em 0 2em 0"
        },
    }
};