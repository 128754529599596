import React from "react";
import {FaExchangeAlt} from "react-icons/fa";
import Navigation from "components/navigation/navigation";
import {store} from 'react-notifications-component'
import {NotificationFactory} from "components/notification/notification";
import {useDispatch, useSelector} from "react-redux";
import {setViewer} from "store/viewer/action-creator";
import {CreateTheme} from "hooks/theme";
import useStyles from "./header-bar.styles";
import {saveProject} from "applications/project/actions/actions";
import Button from "react-bootstrap/Button";
import {logoutUser} from "../../applications/authentication/actions/actions";
import {Constants} from "../../constants";

export const HeaderBar = ({enableSave = true}) => {

    /**
     * Change view editing mode/static view
     */

    const classes = CreateTheme(useStyles, true);

    const viewer = useSelector(s => s.viewer);

    const project = useSelector(s => s.project);

    const theme = useSelector(s => s.theme);

    const dispatch = useDispatch();

    const changeViewHandle = () => {
        viewer ? dispatch(setViewer(false)) : dispatch(setViewer(true));

        store.addNotification(NotificationFactory.create("Zobrazení", `Zobrazení bylo přepnuto.`, "info"));
    };

    const saveChangesHandle = async () => {
        dispatch(await saveProject(project, theme));

        store.addNotification(NotificationFactory.create("Uložení změn", `Vaše změny byly publikovány.`, "info"));
    };

    const logoutUserHandle = async () => {
        dispatch(await logoutUser());
        document.location.replace(Constants.Routes.Login);
    };

    return (
        <div className={"header-bar--component"}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <p>{project.owner.name}<br/></p>
                    <div><small>{project.name}</small></div>
                </div>

                <div className={classes.rightSide}>
                    <Navigation/>
                    <span className={classes.mode}>{viewer ? "Náhledový mód" : "Editační mód"}</span>
                    <button onClick={changeViewHandle} className={classes.changeView}><FaExchangeAlt
                        className={classes.icon}/></button>

                    {enableSave ? <div>
                            <Button onClick={saveChangesHandle} className={classes.saveChanges}>Uložit změny</Button>
                            <Button onClick={logoutUserHandle} className={classes.saveChanges}>Odhlásit se</Button>
                        </div>
                        : ""}
                </div>
            </div>
        </div>
    )
}