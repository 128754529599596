import React from "react";
import {allSections} from "components/sections/sections";
import {useSelector} from "react-redux";
import {Constants} from "../../constants";
import {CreateTheme} from "hooks/theme";
import useStyles from "./section.styles";

export const Section = ({index, section, lastInSections = false}) => {

    const classes = CreateTheme(useStyles, true);

    const mode = useSelector(s => s.mode);

    const Section = allSections[section.componentName];

    const onMouseEnterHandle = (e) => {
        e.currentTarget.style.borderColor = "#000";
    };

    const onMouseLeaveHandle = (e) => {
        e.currentTarget.style.borderColor = "transparent";
    };

    const EditModeContent = () => {
        return (
            <div style={getSectionStyles()} className={classes.root}
                 onMouseOver={onMouseEnterHandle} onMouseOut={onMouseLeaveHandle}>
                <Section section={section} sectionIndex={index} />
            </div>
        );
    };

    const ViewModeContent = () => (
        <div style={getSectionStyles()} className={classes.root}>
            <Section section={section} sectionIndex={index}/>
        </div>
    );

    const getSectionStyles = () => {
        let styles = {};

        if(mode === Constants.Mode.CMS) {
            styles.border = "1px dotted transparent";
        }

        if (!section.theme) {
            return styles;
        }

        styles.backgroundColor = section.theme.bgColor;

        if (section.theme.bgImage !== undefined) {
            styles.backgroundImage = `url(${section.theme.bgImage.path})`;
        }

        return styles;
    };

    return (
        <div className={`section--component ${!lastInSections ? "lax" : ""}`} data-lax-preset="fadeIn">
            {mode === Constants.Mode.CMS ? <EditModeContent /> : <ViewModeContent />}
        </div>
    );
};