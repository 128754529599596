import React from 'react';
import Loader from "components/loader/loader";
import ReactNotification from "react-notifications-component";
import {SharedStyles} from "components/app/shared-styles";
import {Router} from "../../router/router";

const AppWithTheme = () => {
    return (
        <>
            <SharedStyles/>
            <ReactNotification/>
            <Loader/>

            <Router />
        </>
    )
};

export default AppWithTheme;