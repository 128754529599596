import React, {useState} from "react";
import {MyImages} from "components/my-images/my-images";
import {useDispatch, useSelector} from "react-redux";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {updateSectionImages} from "store/pages/action-creator";
import {fillImageContents} from "components/sections/sections";
import Img from "react-cloudinary-lazy-image";
import {Utilities} from "utilities/utilities";
import {Constants} from "../../../constants";
import {CreateTheme} from "hooks/theme";
import useStyles from "./carousel.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaCloudUploadAlt} from "react-icons/fa";

export const Carousel = ({section, sectionIndex, interval = null}) => {

    const classes = CreateTheme(useStyles);

    const project = useSelector(s => s.project);

    const allImages = useSelector(s => s.images);

    const dispatch = useDispatch();

    const [openMyImages, setOpenMyImages] = useState(false);

    const imageChangeHandle = (images) => {
        images.forEach((image, index) => {
            dispatch(updateSectionImages(image.id, project, index + 1, sectionIndex));
        });
    };

    const imageContents = [
        {position: 1, value: Constants.Images.DEFAULT_CAROUSEL_IMAGE_1},
        {position: 2, value: Constants.Images.DEFAULT_CAROUSEL_IMAGE_2}
    ];

    fillImageContents(allImages, section.images, imageContents);

    const ChildActions = () => (
        <div onClick={() => setOpenMyImages(true)}>
            <FaCloudUploadAlt className={"icon"}/>
        </div>
    );

    const id = "carousel__section-index-" + sectionIndex;

    return (
        <div className={`carousel--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section} childActions={<ChildActions/>}>
                <div className={classes.root}>


                    <div id={id} className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {imageContents.map((image, index) => (
                                <li key={index} data-target={`#${id}`} data-slide-to={index}
                                    className={index === 0 ? "active" : ""}/>
                            ))}
                        </ol>
                        <div className="carousel-inner">

                            {imageContents.map((image, index) => (

                                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                    <div className={classes.content}>
                                        <Img
                                            fadeIn={true}
                                            cloudName={Utilities.Cloudinary.getCloudName()}
                                            imageName={`${image.value.folder}/${image.value.filename}`}
                                            fluid={{
                                                maxWidth: 1920,
                                                height: 1080
                                            }}

                                            urlParams={"c_scale"}
                                        />
                                        <div className={classes.contentInner}>
                                            <SectionWidgets section={section} sectionIndex={sectionIndex}
                                                            positionInSection={1}/>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                        <a className="carousel-control-prev" href={`#${id}`} role="button"
                           data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"/>
                        </a>
                        <a className="carousel-control-next" href={`#${id}`} role="button"
                           data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"/>
                        </a>
                    </div>

                    <MyImages onImageSelect={imageChangeHandle} onClose={() => setOpenMyImages(false)}
                              isOpen={openMyImages}
                              multipleSelection={true}/>
                </div>
            </ObjectActions>
        </div>
    );
}