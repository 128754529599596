import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.topBar;

    return {
        root: {
            borderBottom: currentTheme.borderBottom
        },

        wrap: currentTheme.wrap,

        phoneIcon: {
            ...currentTheme.icons,

            borderRight: currentTheme.iconPhone
        },

        emailIcon: {
            extend: "phoneIcon"
        },

        svg: currentTheme.iconsSvg
    }
});

export default classes;

