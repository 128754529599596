import {DefaultSection} from "components/sections/default/default";
import {TwoColsSection} from "components/sections/two-cols/two-cols";
import {ThreeColsSection} from "components/sections/three-cols/three-cols";
import {TopBar} from "components/sections/top-bar/top-bar";
import {Menu} from "components/sections/menu/menu";
import {Carousel} from "components/sections/carousel/carousel";
import {ImageFloat} from "components/sections/image-float/image-float";
import {ThreeCard} from "components/sections/three -card/three-card";
import {MultiCols} from "components/sections/multi-cols/multi-cols";
import {FluidContainer} from "components/sections/fluid-container/fluid-container";
import {Separator} from "components/sections/separator/seaprator";

export const allSections = {
    "DefaultSection": DefaultSection,
    "TwoColsSection": TwoColsSection,
    "ThreeColsSection": ThreeColsSection,
    "TopBar": TopBar,
    "Menu": Menu,
    "Carousel": Carousel,
    "ImageFloat": ImageFloat,
    "ThreeCard": ThreeCard,
    "MultiCols": MultiCols,
    "FluidContainer": FluidContainer,
    "Separator": Separator
};

/*export const getFilledSectionValues = (section, contents) => {
    if (section.contents.length > 0) {
        section.contents.forEach((section) => {
            const index = contents.findIndex(s => s.position === section.position);

            if(index >= 0) {
                contents[index].value = section.value;
            }

        });
    }

    return contents;
}*/

export const fillWidgetContents = (widget, contents) => {
    if (widget.contents.length > 0) {
        widget.contents.forEach((content) => {
            const index = contents.findIndex(s => s.position === content.position);

            if(index >= 0) {
                contents[index].value = content.value;
            }

        });
    }
}

export const fillImageContents = (allImages, sectionImages, imageContents) => {
    allImages.forEach((dbImage) => {
        if(sectionImages.some(s => s.imageId === dbImage.id)) {
            const sectionImage = sectionImages.find(s => s.imageId === dbImage.id);

            const existingIndex = imageContents.findIndex(s => s.position === sectionImage.position);

            if(existingIndex >= 0) {
                imageContents[existingIndex] = {position: sectionImage.position, value: dbImage};
            }else {
                imageContents.push({position: sectionImage.position, value: dbImage})
            }
        }
    });
}