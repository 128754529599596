import {ConfigurationService} from "services/configuration-service";
import {store} from "react-notifications-component";
import {NotificationFactory} from "../components/notification/notification";
import {authHeader} from "../helpers/auth-header";
import history from "helpers/history";
import {Constants} from "../constants";

const axios = require('axios').default;

export class Service {

    static instance;

    constructor() {

        this.config = new ConfigurationService();

        this.axios = axios.create({
            baseURL: this.config.getConfigItem("REACT_APP_API_URL"),
            headers: {...authHeader()}
        });
    }

    static errorHandle(error) {

        error.hasError = true;

        if(error.response === undefined) {
            history.push(Constants.Routes.Errors.ProjectError);
            return;
        }

        const errorMessage = error.response.data.detail;

        store.addNotification(NotificationFactory.create("Něco se pokazilo", errorMessage, "danger"));
        console.log(error);

        return error.response;
    }

    static successHandle(response) {
        return response ? response.data : {};
    }
}