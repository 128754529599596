import React, {useState} from "react";
import {MyImages} from "components/my-images/my-images";
import {useDispatch, useSelector} from "react-redux";
import {updateSectionImages} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillImageContents} from "components/sections/sections";

import {UploadableImage} from "components/uploadable-image/uploadable-image";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Img from "react-cloudinary-lazy-image";
import {Utilities} from "utilities/utilities";
import {ObjectActions} from "components/object-actions/object-actions";
import {SectionWidgets} from "components/section-widgets/section-widgets";

export const ImageFloat = ({section, sectionIndex}) => {

    const project = useSelector(s => s.project);

    const allImages = useSelector(s => s.images);

    const dispatch = useDispatch();

    const [openMyImages, setOpenMyImages] = useState(false);

    const updateSectionImagesHandle = (selectedImage, imagePosition) => {
        dispatch(updateSectionImages(selectedImage.id, project, imagePosition, sectionIndex));
    };

    let imageContents = [
        {position: 1, value: Constants.Images.DEFAULT_LOGO},
    ];

    fillImageContents(allImages, section.images, imageContents);

    return (
        <div className={`image-float--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section}>
                <Container>
                    <Row spacing={3} className={"align-items-center"}>
                        <Col xs={12} sm={6}>
                            <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={1}/>
                        </Col>

                        <Col xs={12} sm={6}>

                            <UploadableImage onClick={() => setOpenMyImages(true)}>
                                <Img
                                    fadeIn={true}
                                    cloudName={Utilities.Cloudinary.getCloudName()}
                                    imageName={`${imageContents[0].value.folder}/${imageContents[0].value.filename}`}
                                    fluid={{
                                        maxWidth: 640,
                                        height: 480
                                    }}

                                    urlParams={"c_scale"}
                                />

                            </UploadableImage>
                        </Col>

                    </Row>

                    <MyImages onImageSelect={(images) => updateSectionImagesHandle(images[0], 1)}
                              onClose={() => setOpenMyImages(false)} isOpen={openMyImages}/>
                </Container>
            </ObjectActions>
        </div>
    );
}
