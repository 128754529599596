import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.objectAdder;

    return {
        root: {
            ...currentTheme.root,
            "&:hover $content": currentTheme.contentActive
        },
        rootActive: {
            extend: "root"
        },
        rootStatic: {
            ...currentTheme.rootStatic,
            "& $content": currentTheme.contentActive
        },
        content: currentTheme.content,
        icon: currentTheme.icon,
        button: currentTheme.button
    }
});

export default classes;

