import {setLoader} from "../../../../store/loader/action-creator";
import history from "../../../../helpers/history";
import {loggedUserGetter} from "../../../../helpers/logged-user-getter";
import {Themes} from "../../../../templates/templates";
import {setTheme} from "../../../../store/theme/actions/action-creator";
import {loadImagesSuccess} from "../../../../store/images/actions/actions";
import {setEditingPage} from "../../../../store/pages/action-creator";
import {loadStaticDataSuccess} from "../../../../store/static-data/actions/load-static-data-success";
import {Constants} from "../../../../constants";
import {projectLoadSuccess} from "../../../../store/project/actions/project-load-success";

let lastProjectId = null;

const changeEditingPage = (dispatch, project, pathname) => {
    let editingPage = {};

    for (let item of project.pages) {
        if (item.url === pathname) {
            editingPage = item;
            break;
        }
    }

    if (editingPage.id !== undefined && editingPage.id > 0) {
        dispatch(setEditingPage(editingPage.id, project));
    }
}

export const createLoadProjectAction = (deps) =>
    (location) => //ThunkAction
        async (dispatch) => { //Promise

            const savedProjectId = localStorage.getItem("iws.cms.frontend.selectedProject");

            if (!savedProjectId) {
                history.push(Constants.Routes.MyProjects);
            }

            if(lastProjectId === savedProjectId) {
                return Promise.resolve(true);
            }

            dispatch(setLoader(true));

            lastProjectId = savedProjectId;

            const user = loggedUserGetter();
            const project = await deps.projectService.getProjectById(user.id, savedProjectId);
            const images = await deps.imageService.loadProjectImages(user.id, project.id);
            const staticContent = await deps.staticContentService.getAll(user.id);

            if (!project.id) {

                //hide loader
                dispatch(setLoader(false));

                history.push(Constants.Routes.Errors.ProjectError);
                return Promise.resolve(true);
            }

            const currentTheme = Themes[project.templateName];

            if (currentTheme !== undefined) {
                const link = document.createElement("link");
                link.setAttribute("href", currentTheme.fonts.sourceLink);
                link.setAttribute("rel", "stylesheet");
                document.head.appendChild(link);

                dispatch(setTheme(currentTheme));
            }

            changeEditingPage(dispatch, project, location.pathname);

            //load project attributes
            dispatch(projectLoadSuccess(project))

            //load static content
            dispatch(loadStaticDataSuccess(staticContent));

            //load project images
            dispatch(loadImagesSuccess(images));

            dispatch(setLoader(false));
        };
