import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isUserLoggedIn} from "../../helpers/is-user-logged-in";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            isUserLoggedIn()
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
}