import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteSection, deleteWidget, updateSectionImages} from "store/pages/action-creator";
import {CreateTheme} from "hooks/theme";
import {Popover} from "../popover/popover";
import RemoveDialog from "components/remove-dialog/remove-dialog";
import {FaImage, FaTimes, FaTrash} from "react-icons/fa";
import {Constants} from "../../constants";

import useStyles from "./object-actions.styles";
import {BreakpointsAction} from "./actions/breakpoints-action/breakpoints-action";
import {VerticalAlignmentAction} from "./actions/vertical-alignment-action/vertical-alignment-action";
import {MyImages} from "../my-images/my-images";
import {HorizontalAlignmentAction} from "./actions/horizontal-alignment-action/horizontal-alignment-action";

export const ObjectActions = ({object, sectionIndex, children, childActions, type = Constants.ObjectTypes.SECTION}) => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    const mode = useSelector(s => s.mode);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const target = useRef(null);

    const [openMyImages, setOpenMyImages] = useState(false);

    const updateObjectImagesHandle = (selectedImage, imagePosition) => {
        if (type === Constants.ObjectTypes.SECTION) {
            dispatch(updateSectionImages(selectedImage.id, project, imagePosition, object.position));
        }
    };

    const toggle = () => setPopoverOpen(!popoverOpen);

    const getParents = (element) => {
        let els = [];
        while (element) {
            els.unshift(element);
            element = element.parentNode;
        }

        return els;
    }

    const togglePopoverHandle = (e) => {
        e.preventDefault();

        let parents = getParents(e.currentTarget)
            .map(s => s.classList)
            .filter(s => s !== undefined && s.value !== "")
            .map(s => Array.from(s.values()));

        parents = [].concat.apply([], parents);

        //Check if event is for widget nad type is widget too, or is section and type is section too. It's because
        if (parents.some(s => s.includes("widget") && type === Constants.ObjectTypes.WIDGET)) {
            e.stopPropagation();
        }

        toggle();
    };

    const toggleDeleteDialogHandle = () => {
        setDeleteDialog(!deleteDialog);
    };

    const deleteObjectHandle = () => {

        if (type === Constants.ObjectTypes.SECTION) {
            dispatch(deleteSection(sectionIndex, project));
        } else if (type === Constants.ObjectTypes.WIDGET) {
            dispatch(deleteWidget(object, project, sectionIndex));
        }
    };

    const id = "object-actions__" + type + "__object-index-" + sectionIndex + "_" + object.id;

    const getEditingContent = () => (
        <div className={"object-actions--component"} onClick={togglePopoverHandle}>
            <div ref={target} id={id} className={classes.root}>

                <Popover title={"Možnosti " + type} onClickOutside={() => {
                }} target={target} id={id} isOpen={popoverOpen}>

                    {/*<ShareBlockAction type={type} object={object} />*/}

                    <div className={"d-flex justify-content-center align-items-center"}>
                        <BreakpointsAction object={object} type={type}/>

                        <span>&nbsp;|&nbsp;</span>

                        {type === Constants.ObjectTypes.SECTION && <VerticalAlignmentAction object={object} type={type} />}

                        {type === Constants.ObjectTypes.WIDGET && <HorizontalAlignmentAction object={object} type={type} />}
                    </div>

                    <div className={"d-flex justify-content-start align-items-center w-100 mt-2"}>
                        <span className={classes.closeIcon}><FaTimes/></span>
                        <div onClick={toggleDeleteDialogHandle}>
                            <FaTrash className={"icon"}/>
                        </div>

                        {type === Constants.ObjectTypes.SECTION && <div onClick={() => setOpenMyImages(true)}><FaImage className={"icon"}/></div>}

                        {childActions}

                    </div>

                </Popover>

                <RemoveDialog isOpen={deleteDialog} onClose={() => setDeleteDialog(false)}
                              onReject={() => setDeleteDialog(false)} onSuccess={deleteObjectHandle}/>

                <MyImages onImageSelect={(images) => updateObjectImagesHandle(images[0], 1)}
                          onClose={() => setOpenMyImages(false)} isOpen={openMyImages}/>
            </div>

            {children}
        </div>
    );

    return (
        <>{mode === Constants.Mode.CMS ? getEditingContent() : children}</>
    );
};