import {Utilities} from "utilities/utilities";

export const components = (config) => ({
    card: {
        bgColor: "#efefef",

        content: {
            padding: "1em",
            color: "#000000"
        },

        image: {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "10em",
            width: "100%"
        },

        imageWrap: {
            margin: "auto"
        },

        actions: {
            marginTop: "1em"
        }
    },

    loader: {
        root: {
            width: "100%",
            height: "100%",
            backgroundColor: `#ffffff90`,
            position: "fixed",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 10
        },

        inner: {
            position: "absolute",
            left: "50%",
            top: "30%",
            marginLeft: "-2em"
        },

        loadingText: {
            display: "block"
        },

        svg: {
            width: "4em",
            height: "5em",
            margin: "1em auto",
            display: "#000000"
        }
    },

    pageAttributeForm: {
        textField: {
            backgroundColor: `#00000040`,
            fontSize: "1em",
            border: "none",
            borderBottom: `2px solid transparent`,
            padding: "1em",
            outline: "none",
            transition: `border ease-in-out .2s`
        },
        textFieldFocus: {
            borderColor: `#00000050`
        },
        textArea: {
            width: "100%",
            resize: "none"
        },

        label: {
            fontSize: "1em",
            fontWeight: "bold"
        },

        button: {
            marginTop: "1em",
            backgroundColor: `#00000080`,
            border: "none",
            color: "#ffffff",
            padding: "0.7em",
            transition: "background ease-in-out .2s"

        },

        buttonHover: {
            backgroundColor: `#00000010`
        }
    },

    section: {
        root: {
            padding: 0,
            width: "100%",
            //minHeight: "3em",
            position: "relative",
            transition: "border ease-in-out .2s",
            marginTop: "0",
            backgroundSize: "cover",
            overflow: "hidden"
        },

        rootHoverIcon: {
            fill: "#ccc",
            transition: "fill ease-in-out .2s"
        },

        icon: {
            cursor: "pointer",
            marginRight: "0.5em",
            fill: "transparent"
        },

        iconHover: {
            fill: "#000"
        },

        editBlock: {
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "4%",
            top: "50%",
            marginTop: "-0.5em",
            fontSize: "1.2em",
            zIndex: 3
        }
    },

    sectionWidgets: {
        root: {
            position: "relative",
            zIndex: 3
        },

        wrap: {
            position: "relative"
        },

        deleteIcon: {
            display: "inline-block",
            padding: "0.5em",
            cursor: "pointer",
            zIndex: 3
        },

        actions: {
            display: "flex",
            justifyContent: "center"
        }
    },

    objectAdder: {

        root: {
            padding: "1em",
            zIndex: 2,
            cursor: "pointer",
            margin: "auto"
        },

        rootStatic: {
            position: "static"
        },

        icon: {
            fontSize: "1em",
            fill: "#fff",
            marginLeft: "0.5em",
            marginRight: "0.5em"
        },

        content: {
            color: "#fff",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            opacity: 0,
            padding: 0,
            position: "relative"
        },

        button: {
        }
    },

    selectBox: {
        root: {
            width: "9em"
        }
    },

    objectActions: {
        root: {
            width: "25em"
        },

        closeIcon: {
            position: "absolute",
            top: "-1.4em",
            right: "0.5em",
            fontSize: "1.2em",
            cursor: "pointer"
        }
    },

    sectionAdder: {
        root: {
            bottom: "-3em"
        }
    },

    sectionSettings: {

        backgrounds: {
            display: "flex"
        },

        background: {
            marginRight: "0.5em"
        },

        bgImage: {
            width: "auto",
            height: "3em",
            margin: 0,
            display: "block"
        },

        color: {
            width: "3em",
            height: "3em",
            marginRight: "0.5em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },

        colorPlus: {
            backgroundColor: "#fff"
        },

        header: {
            fontSize: "1.5em",
            color: "#fff"
        },

        plusIcon: {
            fontSize: "2em"
        }
    },

    fileUpload: {
        root: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "2em"
        },

        uploadArea: {
            border: "1px solid #ccc",
            padding: "0.5em 1em"
        },

        icon: {
            fontSize: "3em"
        },
        uploadText: {
            fontSize: "0.8em",
        }
    },

    modal: {
        root: {
            maxWidth: "50vw",
            margin: "auto",
            position: "absolute",
            background: "#f3f3f3",
            border: "1px solid #ccc",
            zIndex: 5,
            overflowY: "auto",
            maxHeight: "36em"
        },
        overlay: {
            zIndex: 5
        },

        title: {
            padding: "0.5em"
        },

        body: {
            padding: "2rem"
        },

        content: {
            backgroundColor: config.colors.white
        },

        closeIcon: {
            position: "absolute",
            top: "1.5%",
            right: "1%",
            fontSize: "1.2em",
            cursor: "pointer"
        }
    },

    uploadableImage: {
        root: {
            position: "relative",
            backgroundColor: "transparent",
            transition: "all ease-in-out .2s",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            maxHeight: "inherit"
        },

        uploadImage: {
            visibility: "hidden",
            fontSize: "3em",
            fill: "#bfbfbf",
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            opacity: 0.8,
            zIndex: 2
        },

        uploadImageHover: {
            visibility: "visible"
        },

        children: {
            width: "100%",
            opacity: 1,
            transition: "all linear .2s"
        },

        childrenHover: {
            opacity: 0.8
        }
    },

    navigation: {
        list: {
            display: "flex",
            alignItems: "center"
        },

        link: {
            padding: "0.5em",
            display: "block",
            color: "#fff"
        },

        linkHover: {
            color: "#ccc",
            textDecoration: "none"
        },

        icon: {
            fontSize: "1.5em"
        }
    },

    myImages: {

        root: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center"
        },

        imageWrap: {
            border: "1px solid transparent",
            transition: "border ease-in-out .2s",
            position: "relative"
        },

        imageWrapSelected: {
            borderColor: "blue"
        },

        image: {
            padding: "1em",
            margin: "0 auto",
            height: "5em",
            width: "auto",
            zIndex: 1
        },

        deleteIcon: {
            position: "absolute",
            right: "3%",
            fill: "#ccc",
            fontSize: "1.2em",
            top: "6%",
            transition: "all ease-in-out .2s",
            cursor: "pointer",
            zIndex: 2
        },

        settingsIcon: {
            extend: "delete",
            right: "20%"
        },

        iconHover: {
            fill: "#000"
        },

        attributes: {
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#f7f7f7",
            zIndex: 5,
            width: "100%",
            height: "80%",
            padding: "1em"
        }
    },

    headerBar: {
        root: {
            fontFamily: "Arial, Helvetica, sans-serif",
            width: "100%",
            backgroundColor: "#000",
            padding: "1em",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            [Utilities.Jss.mediaQueryMaxWidth(config.grid.breakpoints.sm)]: {
                flexDirection: "column"
            }
        },


        header: {
            fontSize: "1.3em",
            color: "#fff"
        },

        saveChangesButton: {
            background: "none",
            border: "1px solid #fff",
            borderRadius: 0,
            color: "#fff",
            padding: "1em",
            fontSize: "1em",
            marginRight: "1em"
        },

        icon: {
            fill: "#fff",
            height: "2em",
            width: "2em"
        },

        changeView: {
            background: "none",
            border: "none",
            marginRight: "2em",
            cursor: "pointer"
        },

        mode: {
            color: "#fff",
            marginRight: "1em",
            borderLeft: "1px solid #fff",
            padding: "0 1em",
            marginLeft: "0.5em"
        },

        rightSide: {
            display: "flex",
            alignItems: "center",

            [Utilities.Jss.mediaQueryMaxWidth(config.grid.breakpoints.sm)]: {
                "flex-wrap": "wrap"
            }
        }
    },

    popover: {

        root: {
            maxWidth: "100%",
            zIndex: "5",
        },

        wrap: {
            backgroundColor: "#c1c1c1 !important",
            maxWidth: "32em !important",
            width: "25em",
            height: "auto",
        },

        header: {
            backgroundColor: "#e2e2e2 !important",
            color: "#000",
            padding: "0.5em"
        },

        contentList: {
            position: "relative",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            padding: "0.5em",
            justifyContent: "center"
        },

        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1em",
            padding: "2em",
            border: "1px solid #111",
            borderRadius: "10px",
            margin: "0.5em 0.5em",
            transition: "background-color ease-in-out .2s",
            width: "5em",
            height: "5em",
            color: "#111",
            textAlign: "center",
            cursor: "pointer",
        },

        contentHover: {
            backgroundColor: "#BCBCBC",
            borderColor: "#fff",
            color: "#000"
        },

        arrow: {
            borderBottomColor: "#000 !important"
        },

        icon: {
            fontSize: "1.5em",
            fill: "#000000",
            marginLeft: "0.5em",
            marginRight: "0.5em",
            transition: `all ease-in-out .2s`,
            cursor: "pointer"
        },

        iconHover: {
            fill: Utilities.Jss.lightenDarkenColor("#000000", 60)
        }
    },

    editor: {
        modalContent: {
            backgroundColor: "#e4e4e4",
        },

        editorWrap: {
            border: "1px solid #969696",
            borderRadius: "5px"
        },

        textWrap: {
            padding: "2em"
        },

        textWrapSingleMode: {
            margin: 0
        },

        textWrapButton: {
            padding: 0,
            margin: 0
        }
    },

    backgroundColors: {

        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },

        colorItem: {
            width: "3em",
            height: "3em",
            marginLeft: "0.5em",
            marginRight: "0.5em",
            border: "1px solid #000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },

        svg: {
            fontSize: "2em"
        }
    },

    motiveSelector: {
        root: {},
        item: {
            textDecoration: "none"
        },
        colorItem: {
            width: "1.5em",
            height: "1.5em",
            border: "1px solid #000"
        }
    }
});