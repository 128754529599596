import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.sections.twoCols;
    return {
        root: currentTheme.root,
        col: currentTheme.col,
        colWrap: currentTheme.colWrap
    }

});

export default classes;

