import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.backgroundColors;

    return {
        root: currentTheme.root,
        colorItem: currentTheme.colorItem,
        svg: currentTheme.svg
    }
});

export default classes;


