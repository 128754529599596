import {isUserLoggedIn} from "../../helpers/is-user-logged-in";
import {loggedUserGetter} from "../../helpers/logged-user-getter";
import {authenticationActions} from "./actions/actions";

let user = loggedUserGetter();
const initialState = isUserLoggedIn() ? {loggedIn: true, user} : {};

export const AuthenticationReducer = (state = initialState, action) => {

    switch (action.type) {
        case authenticationActions.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };

        case authenticationActions.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };

        case authenticationActions.LOGIN_FAILURE:
            return {};

        case authenticationActions.LOGOUT:
            return {};

        default:
            return state
    }
};