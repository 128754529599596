import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.motiveSelector;

    return {
        root: {
            ...currentTheme.root,
            "& .dropdown-item": currentTheme.item
        },
        colorItem: currentTheme.colorItem
    }
});

export default classes;


