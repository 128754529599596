export const Constants = {
    Mode: {
        CMS: "cms",
        VIEW: "view"
    },
    Strings: {
        LINK_PLACEHOLDER: "Váš odkaz",
        HEADER_PLACEHOLDER: "Nadpis",
        TEXT_PLACEHOLDER: "Váš text začíná zde...",
        FORM_EMAIL_TO: "info@iwsolutions.cz",
        FORM_EMAIL_PLACEHOLDER: "E-mail",
        FORM_TEXTAREA_PLACEHOLDER: "Obsah zprávy...",
        LONG_TEXT_PLACEHOLDER: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum odio risus sit amet ante. Donec iaculis gravida nulla. Fusce aliquam vestibulum ipsum. Sed ac dolor sit amet purus malesuada congue. Integer malesuada. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis pulvinar. Maecenas aliquet accumsan leo. Aliquam erat volutpat. Cras elementum. Suspendisse sagittis ultrices augue. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Nulla pulvinar eleifend sem. Fusce consectetuer risus a nunc. Proin in tellus sit amet nibh dignissim sagittis. Vivamus luctus egestas leo. Curabitur sagittis hendrerit ante. Duis pulvinar. Nam sed tellus id magna elementum tincidunt.",
        BUTTON_PLACEHOLDER: "Vaše tlačítko"
    },

    Images: {
        DEFAULT_LOGO: {filename: "default-logo.png", folder: "", version: "1588066546"},
        DEFAULT_CAROUSEL_IMAGE_1: {filename: "sample.png", folder: "", version: ""},
        DEFAULT_CAROUSEL_IMAGE_2: {filename: "sample.png", folder: "", version: ""},
    },

    ObjectTypes: {
        WIDGET: "widget",
        SECTION: "section"
    },

    Editor: {
        Mode: {
            LONG_TEXT: "longText",
            SINGLE_TEXT: "singleText",
            BUTTON: "button"
        }
    },

    Routes: {
        Homepage: "/",
        MyPages: "/my-pages",
        MyProjects: "/my-projects",
        PageSettings: "/page-settings",
        Login: "/login",
        Errors: {
            ProjectError: "/errors/project"
        }
    }
};