import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.myImages;

    return {
        root: currentTheme.root,
        delete: {
            ...currentTheme.deleteIcon,
            "&:hover": currentTheme.iconHover
        },
        settings: {
            ...currentTheme.settingsIcon,
            "&:hover": currentTheme.iconHover
        },
        image: currentTheme.image,
        imageWrap: currentTheme.imageWrap,
        imageWrapSelected: {
            ...currentTheme.imageWrap,
            ...currentTheme.imageWrapSelected
        },

        attributes: currentTheme.attributes,
        closeIcon: theme.components.modal.closeIcon
    }
});

export default classes;

