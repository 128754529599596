import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateWidgetImages} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillImageContents} from "components/sections/sections";
import {MyImages} from "components/my-images/my-images";
import Img from 'react-cloudinary-lazy-image'
import {Utilities} from "utilities/utilities";

import useStyles from "./image.styles";
import {CreateTheme} from "hooks/theme";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaCloudUploadAlt} from "react-icons/fa";
import RangeSlider from 'react-bootstrap-range-slider';
import {setWidgetPercentSize} from "../../../store/pages/action-creator";

export const Image = ({data, sectionIndex}) => {
    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();
    const project = useSelector(s => s.project);

    const allImages = useSelector(s => s.images);

    const [openMyImages, setOpenMyImages] = useState(false);

    const updateWidgetImagesHandle = (selectedImage, imagePosition) => {
        dispatch(updateWidgetImages(selectedImage.id, data, project, imagePosition));
    };

    const changeRangeHandle = (changeEvent) => {
        dispatch(setWidgetPercentSize(Number(changeEvent.target.value), data, project));
    }

    const imageContents = [
        {position: 1, value: Constants.Images.DEFAULT_LOGO}
    ];

    fillImageContents(allImages, data.images, imageContents);

    const ChildActions = () => (
        <div className={"d-flex justify-content-center align-items-center"}>
            <div onClick={() => setOpenMyImages(true)}>
                <FaCloudUploadAlt className={"icon"}/>
            </div>

            <span>Velikost:&nbsp;</span>

            <RangeSlider
                tooltipLabel={(value) => `${value}%`}
                variant={"dark"}
                min={0} //TODO: nahradit statické hodnoty z aktuálního configu
                max={200}
                step={10}
                value={data.percentSize}
                onChange={changeRangeHandle}
            />
        </div>
    );

    return (
        <div className={`image--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <ObjectActions
                sectionIndex={sectionIndex}
                object={data}
                type={Constants.ObjectTypes.WIDGET}
                childActions={<ChildActions/>}
            >
                <div
                    className={`${classes.root} ${data.percentSize !== 100 ? classes["multiplier" + data.percentSize] : ""} ${Utilities.Cms.getHorizontalAlignmentClasses(data)}`}>

                    <Img
                        imgStyle={Utilities.Cms.getHorizontalAlignmentClasses(data) === "" ? {display: "block"} : {}}
                        alt={imageContents[0].value.alt}
                        fadeIn={true}
                        backgroundColor={"#ccc"}
                        cloudName={Utilities.Cloudinary.getCloudName()}
                        imageName={`${imageContents[0].value.folder}/${imageContents[0].value.filename}`}
                        version={`v${imageContents[0].value.version}`}
                        fluid={{
                            maxWidth: imageContents[0].value.width || 1920,
                            height: imageContents[0].value.height || 800
                        }}
                    />

                    <MyImages onImageSelect={(images) => updateWidgetImagesHandle(images[0], imageContents[0].position)}
                              onClose={() => setOpenMyImages(false)} isOpen={openMyImages}/>
                </div>
            </ObjectActions>
        </div>
    )
};