import React, {useState} from "react";
import {Editor} from "../../editor/editor";
import {useDispatch, useSelector} from "react-redux";
import {updateWidgetContents} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {ObjectActions} from "components/object-actions/object-actions";
import {FaEdit} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./textarea.styles";
import {Utilities} from "utilities/utilities";

export const Textarea = ({data, sectionIndex, customContents = null, simplyMode = false}) => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    const updateWidgetHandle = (value, valuePosition) => {
        dispatch(updateWidgetContents(value, data, project, valuePosition, sectionIndex))
    };

    const [openEditor, setOpenEditor] = useState(false);

    let contents = customContents || [
        {position: 1, value: Constants.Strings.TEXT_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);

    const ChildActions = () => (
        <>
            <div onClick={() => setOpenEditor(true)}>
                <FaEdit className={"icon"}/>
            </div>
        </>
    );

    return (
        <div className={`textarea--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <div className={classes.root}>
                <ObjectActions
                    sectionIndex={sectionIndex}
                    object={data}
                    type={Constants.ObjectTypes.WIDGET}
                    childActions={<ChildActions/>}
                >

                    <Editor openDialog={openEditor} onClose={() => setOpenEditor(false)}
                            onSave={(value) => updateWidgetHandle(value, contents[0].position)}
                            currentValue={contents[0].value}
                            mode={simplyMode ? Constants.Editor.Mode.SINGLE_TEXT : Constants.Editor.Mode.LONG_TEXT}/>

                </ObjectActions>
            </div>
        </div>
    )
};