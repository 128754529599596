import {defaultConfig, DefaultTheme} from "templates/default/default";
import {objectDeepCopy} from "protopytes/clone";
import {HaasmontBase} from "templates/haasmont/base";

const haasmontConfig = objectDeepCopy(defaultConfig);

haasmontConfig.colors.primary = "#969696";
haasmontConfig.colors.secondary = "#f6f6f6";
haasmontConfig.colors.tertialy = "#b3b3b3";
haasmontConfig.colors.quaternary = "#d2051e";

const haasmontTheme = objectDeepCopy(DefaultTheme(haasmontConfig));

HaasmontBase(haasmontTheme, haasmontConfig);

haasmontTheme.base.others = {
    ...haasmontTheme.base.others,

    ".section-MultiCols-24": {
        margin: "1em 0 1em 0"
    }
}

export const HaasmontJadroveVrtaniTheme = haasmontTheme;



