import {Service} from "services/service";

export class ImageService extends Service {

    async loadProjectImages(userId, projectId) {
        return this.axios.post(`images/list/${userId}/${projectId}`, {})
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }

    async deleteImage(userId, projectId, imageId) {
        return this.axios.post(`images/delete/${userId}/${projectId}`, {imageId: imageId})
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }

    async addAttributes(attributes, imageId, userId, projectId) {
        return this.axios.post(`images/add-attributes/${userId}/${projectId}`, {
            imageId: imageId,
            attributes: attributes
        })
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }

    async uploadImages(userId, projectId, images) {
        let imageFormData = new FormData();

        images.forEach((image) => {
            imageFormData.append("images", image);
        });

        return this.axios.post(`images/upload/${userId}/${projectId}`, imageFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .catch(Service.errorHandle)
            .then(Service.successHandle);
    }
}