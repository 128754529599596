import {createLoginAction, createlogoutAction} from "./thunks/thunk-action";
import {UserService} from "../../../services/user-service";
import history from "../../../helpers/history";

const userService = new UserService();

export const loginUser = createLoginAction({
    userService: userService,
    history: history
});

export const logoutUser = createlogoutAction({
    userService: userService
});