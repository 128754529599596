import {Constants} from "../../../../constants";
import Select from "react-select";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CreateTheme} from "../../../../hooks/theme";
import useStyles from "./vertical-alignment.styles";
import {SelectBoxItemMapper} from "../../../../helpers/select-box-item-mapper";
import {setSectionVerticalAlignment} from "../../../../store/pages/action-creator";

export const VerticalAlignmentAction = ({object, type}) => {

    const classes = CreateTheme(useStyles, true);

    const dispatch = useDispatch();
    const allVerticalAlignments = useSelector(s => s.staticData.verticalAlignments);
    const project = useSelector(s => s.project);

    const alignments = SelectBoxItemMapper(allVerticalAlignments);
    const selectedAlignmentForSelect = object.verticalAlignment ? alignments.find(b => b.value === object.verticalAlignment.value) : null;

    const alignmentSelectHandle = (data) => {
        if (type === Constants.ObjectTypes.SECTION) {
            dispatch(setSectionVerticalAlignment(data.value, object, project));
        }
    };

    return (
        <div className={"d-flex align-items-center"} onClick={event => event.stopPropagation()}>
            <Select placeholder={"Vertikálně"} className={classes.select} defaultValue={selectedAlignmentForSelect}
                    options={alignments} onChange={alignmentSelectHandle}/>
        </div>);

}