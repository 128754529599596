import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.modal;

    return {
        root: {
            ...currentTheme.root
        },
        title: currentTheme.title,
        body: currentTheme.body,
        content: currentTheme.content,

        overlay: currentTheme.overlay,
        closeIcon: currentTheme.closeIcon
    }
});

export default classes;

