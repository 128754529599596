import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

        const currentTheme = theme.components.popover;

        return {
            root: {
                ...currentTheme.root,
                "& .popover-header": currentTheme.header,
                "& .popover-content": currentTheme.content,
                "& .popover-content:hover": currentTheme.contentHover,
                "& .arrow:after": currentTheme.arrow,
                "& .icon": currentTheme.icon,
                "& .icon:hover": currentTheme.iconHover
            },

            wrap: currentTheme.wrap,
            contentList: currentTheme.contentList
        }
    }
);

export default classes;

