import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.pages.login;

    return {
        root: currentTheme.root,
        header: currentTheme.header,
        input: currentTheme.input,
        button: currentTheme.button

    }
});

export default classes;

