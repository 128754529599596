import React from "react";
import {SectionWidgets} from "../../section-widgets/section-widgets";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CreateTheme} from "hooks/theme";
import useStyles from "./three-cols.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {Utilities} from "utilities/utilities";

export const ThreeColsSection = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    return (
        <div className={`three-cols--section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section}>
                <div className={classes.root}>
                    <Container>
                        <Row spacing={3}>
                            <Col xs={12} sm={4}>
                                <div className={"content-wrap"}>
                                    <SectionWidgets section={section} sectionIndex={sectionIndex}
                                                    positionInSection={1}/>
                                </div>
                            </Col>

                            <Col xs={12} sm={4}>
                                <div className={"content-wrap"}>
                                    <SectionWidgets section={section} sectionIndex={sectionIndex} positionInSection={2}/>
                                </div>
                            </Col>

                            <Col xs={12} sm={4}>
                                <div className={"content-wrap"}>
                                    <SectionWidgets section={section} sectionIndex={sectionIndex}
                                                    positionInSection={3}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ObjectActions>
        </div>
    );
};