import React from "react";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {Utilities} from "utilities/utilities";
import {CreateTheme} from "hooks/theme";
import useStyles from "./logo.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {Textarea} from "components/widgets/textarea/textarea";
import {Image} from "../image/image";

export const Logo = ({data, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    let contents = [
        {position: 1, value: Constants.Strings.TEXT_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);

    return (
        <div className={`logo--widget ${Utilities.Cms.getHiddenClasses(data)}`}>
            <ObjectActions
                sectionIndex={sectionIndex}
                object={data}
                type={Constants.ObjectTypes.WIDGET}
            >
                <div className={`${classes.root} ${Utilities.Cms.getHorizontalAlignmentClasses(data)}`}>

                    <div className={classes.image}>
                        <Image data={data} sectionIndex={sectionIndex}/>
                    </div>

                    <div className={classes.content}>
                    <Textarea sectionIndex={sectionIndex} data={data}
                              customContents={contents.filter(s => s.position === 1)}/>
                    </div>

                </div>
            </ObjectActions>
        </div>
    )
};