import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addSection} from "store/pages/action-creator";
import {FaPlus} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import {Popover} from "../popover/popover";
import useStyles from "./section-adder.styles";
import {Button} from "react-bootstrap";

export const SectionAdder = ({position}) => {

    const classes = CreateTheme(useStyles, true);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    const target = useRef(null);
    const dispatch = useDispatch();

    const sections = useSelector(s => s.staticData.sections);
    const project = useSelector(s => s.project);

    const addSectionHandle = (e, section) => {
        e.stopPropagation();
        section.position = position + 1;

        dispatch(addSection(section, project, position + 1));
        setPopoverOpen(false);
    };

    let className = popoverOpen ? classes.rootActive : classes.root;

    const id = "section-adder__section-index-" + position;

    return (
        <div className={"section-adder--component"}>
            <div ref={target} className={className}>

                {/*<div className={classes.content} onClick={toggle}>
                    <div className={classes.stroke}>
                        <FaCubes className={classes.icon}/>
                    </div>
                </div>*/}
                <div className={"d-flex justify-content-center"}>
                    <Button onClick={toggle} variant={"success"} className={"d-flex align-items-center "}>
                        <FaPlus className={classes.icon} /> Přidat sekci
                    </Button>
                </div>


                <Popover onClickOutside={() => setPopoverOpen(false)} target={target} id={id} isOpen={popoverOpen}>
                    {sections.map((section, index) => (
                        <div className="popover-content" key={index}
                             onClick={(e) => addSectionHandle(e, section)}>{section.name}</div>
                    ))}
                </Popover>
            </div>
        </div>
    );
};