import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.pages.cms;

    return {
        root: {},
        sections: currentTheme.sections
    }

});

export default classes;

