import React from "react";
import {Constants} from "../../constants";
import {useSelector} from "react-redux";
import {FaCloudUploadAlt} from "react-icons/fa";
import {CreateTheme} from "hooks/theme";
import useStyles from "./uploadable-image.styles";

export const UploadableImage = ({onClick, children}) => {

    const classes = CreateTheme(useStyles);

    const mode = useSelector(s => s.mode);
    const viewer = useSelector(s => s.viewer);

    const UploadableImage = (props) => (
        <div className={"uploadable-image--component"}>
            <div className={classes.rootEditMode} onClick={onClick}>
                <FaCloudUploadAlt className={classes.uploadImage}/>
                <div className={classes.children}>
                    {children}
                </div>
            </div>
        </div>
    );

    const StaticImage = (props) => (
        <div className={classes.root}>
            <div className={classes.children}>
                {children}
            </div>
        </div>
    );

    return (mode === Constants.Mode.CMS && !viewer) ? <UploadableImage /> : <StaticImage />

}