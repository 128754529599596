import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import {SectionWidgets} from "components/section-widgets/section-widgets";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CreateTheme} from "hooks/theme";
import useStyles from "./menu.styles";
import {ObjectActions} from "components/object-actions/object-actions";
import {Utilities} from "utilities/utilities";

export const Menu = ({section, sectionIndex}) => {

    const classes = CreateTheme(useStyles);

    const project = useSelector(s => s.project);

    return (
        <div className={`menu-section ${Utilities.Cms.getHiddenClasses(section)}`}>
            <ObjectActions sectionIndex={sectionIndex} object={section}>
                <div className={classes.root}>
                    <Container>
                        <Row className={"align-items-center"}>
                            <Col xs={12} sm={3} md={5} lg={3} className={"d-flex justify-content-start"}>
                                <SectionWidgets section={section} sectionIndex={sectionIndex}/>
                            </Col>

                            <Col>
                                {project.pages.length > 1 ? (
                                    <ul className={classes.list}>
                                        {project.pages.map((page, index) => (
                                            <li key={index}>
                                                <Link className={classes.link} to={page.url}>{page.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                ) : ""}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ObjectActions>
        </div>
    );
};