import {defaultConfig, DefaultTheme} from "templates/default/default";
import {objectDeepCopy} from "protopytes/clone";

const itCaveConfig = objectDeepCopy(defaultConfig);

itCaveConfig.colors.primary = "#ff834f";
itCaveConfig.colors.secondary = "#f6f6f6";

const itCaveTheme = objectDeepCopy(DefaultTheme(itCaveConfig));

export const ItCaveTheme = itCaveTheme;



