import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.components.navigation;

    return {
        list: currentTheme.list,
        link: {
            ...currentTheme.link,
            "&:hover": currentTheme.linkHover
        },
        linkActive: {
            ...currentTheme.link,
            ...currentTheme.linkHover
        },
        icon: currentTheme.icon
    }
});

export default classes;

