import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const html = {
        margin: theme.base.margin,
        padding: theme.base.padding,
        fontSize: theme.base.fontSize,
        fontFamily: theme.fonts.families.primary
    };

    return {
        "@global": {
            body: html,
            html: html,
            ul: theme.base.ulLi,
            li: theme.base.ulLi,
            p: theme.base.p,
            a: theme.base.a,

            ...theme.base.others,

            "@media (min-width: 1200px)": {
                ".container": theme.base.container
            },

            h1: theme.headers.h1,
            h2: theme.headers.h2,
            h3: theme.headers.h3,
            h4: theme.headers.h4,
            h5: theme.headers.h5,
            h6: theme.headers.h6,

            ".section-wrapper": theme.base.sectionWrapper,

            ".react-notification-root p": {
                display: "block"
            },

            ".content-wrap": theme.base.contentWrap,

            "textarea": {
                fontFamily: theme.fonts.families.primary
            }
        }
    }
});

export default classes;

