import React from "react";
import {CreateTheme} from "hooks/theme";
import useStyles from "./motive-selector.styles";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {Utilities} from "utilities/utilities";
import {setWidgetMotive} from "store/pages/action-creator";

export const MotiveSelector = ({ object, isSection = false }) => {

    const classes = CreateTheme(useStyles);

    const theme = useSelector(s => s.theme);

    const project = useSelector(s => s.project);

    const dispatch = useDispatch();

    const onShowDropdownHandle = (isOpen, e) => {
        if (e.stopPropagation) e.stopPropagation();
    };

    const colorSelectHandle = (colorName) => {
        const color = Utilities.Cms.getThemeColors(theme).find(s => s.name === colorName);
        dispatch(setWidgetMotive(color, object, project));
    };

    return (
        <div className={classes.root}>
            <Dropdown onSelect={colorSelectHandle} onToggle={onShowDropdownHandle}>
                <Dropdown.Toggle size={"sm"} variant="secondary" id="dropdown-basic">
                    <span>Vybrat motiv</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item eventKey={undefined}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <span>Žádný</span>
                        </div>
                    </Dropdown.Item>

                    {Utilities.Cms.getThemeColors(theme).map((color, index) => (
                        <Dropdown.Item key={index} eventKey={color.name}>
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <span>Motiv {index + 1}</span>
                                <div className={classes.colorItem} style={{backgroundColor: color.hex}}/>
                            </div>

                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}