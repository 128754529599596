import React from "react";
import {Button, Container} from "react-bootstrap";
import history from "../../helpers/history";

export const ProjectError = () => {
    return (
        <Container>
            <div className={"d-block text-center"}>
                <h1>Houstone máme problém</h1>
                <strong>Neočekávaná chyba projektu</strong>
                <br/>
                <br/>
                <p>Zdá se, že nemáíte přiřazené žádné projekty.</p>
                <p><code>(project.id is null)</code></p>
                <br/>

                <Button variant={"primary"} type={"button"} onClick={() => history.push("/")}>Zkusit znovu</Button>
            </div>
        </Container>
    );
}