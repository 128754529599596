import React from "react";
import {useSelector} from "react-redux";
import {Constants} from "../../constants";
import {allWidgets} from "components/widgets/widgets";
import {WidgetAdder} from "components/widget-adder/widget-adder";
import {CreateTheme} from "hooks/theme";
import useStyles from "./section-widgets.styles";

export const SectionWidgets = ({section, sectionIndex, positionInSection = 1}) => {

    const classes = CreateTheme(useStyles, true);

    const mode = useSelector(s => s.mode);

    const viewer = useSelector(s => s.viewer);

    const getSectionWidgets = () => {
        return section.widgets
            .filter(s => s.positionInSection === positionInSection)
            .sort((a, b) => (a.blockIndex - b.blockIndex));
    };

    const onMouseEnterHandle = (e) => {
        e.currentTarget.style.borderColor = "#ccc";
    };

    const onMouseLeaveHandle = (e) => {
        e.currentTarget.style.borderColor = "transparent";
    };

    const getEditModeContent = () => {
        return (
            <div style={{ border: "1px dashed transparent" }} className={"section-widgets--component"} onMouseOver={onMouseEnterHandle} onMouseOut={onMouseLeaveHandle}>
                <div className={classes.root}>
                    {section.widgets.filter(s => s.positionInSection === positionInSection) <= 0 ? (
                        <WidgetAdder
                            sectionIndex={sectionIndex}
                            sectionId={section.id}
                            positionInSection={positionInSection}
                            blockIndex={-1}
                        />) : ""
                    }

                    {/* Sort by blockIndex */}
                    {getSectionWidgets().map((widget, index) => {

                        const Widget = allWidgets[widget.componentName];

                        return (
                            <div className={classes.wrap} key={index}>

                                <Widget data={widget} widgetIndex={index} sectionIndex={sectionIndex}/>

                                <WidgetAdder
                                    sectionIndex={sectionIndex}
                                    sectionId={section.id}
                                    positionInSection={positionInSection}
                                    blockIndex={index}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const getStaticModeContent = () => {
        return (
            <>
                {getSectionWidgets().map((widget, index) => {

                    const Widget = allWidgets[widget.componentName];

                    return (
                        <Widget key={index} data={widget} widgetIndex={index} sectionIndex={sectionIndex}/>
                    )
                })}
            </>
        );
    };

    return (mode === Constants.Mode.CMS && !viewer) ? getEditModeContent() : getStaticModeContent();
};