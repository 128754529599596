import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.widgets.Textarea;

    return {
        root: {
            "ul, li": currentTheme.ulLi
        }
    }

});

export default classes;

