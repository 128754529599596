import {defaultConfig, DefaultTheme} from "templates/default/default";
import {objectDeepCopy} from "protopytes/clone";
import {HaasmontBase} from "templates/haasmont/base";
import {Utilities} from "utilities/utilities";

const haasmontConfig = objectDeepCopy(defaultConfig);

haasmontConfig.colors.primary = "#f40120";
haasmontConfig.colors.secondary = "#f6f6f6";
haasmontConfig.colors.tertialy = "#969696";

const haasmontTheme = objectDeepCopy(DefaultTheme(haasmontConfig));

HaasmontBase(haasmontTheme, haasmontConfig);

haasmontTheme.headers.h1.fontSize = `${haasmontConfig.fontSizes.baseSize_50}em`;
haasmontTheme.headers.h1.padding = `2em 0 0 0`;

haasmontTheme.headers.h2.fontSize = `${haasmontConfig.fontSizes.baseSize_30}em`;
haasmontTheme.headers.h6.fontSize = `${haasmontConfig.fontSizes.baseSize_20}em`;

haasmontTheme.base.others = {
    ...haasmontTheme.base.others,

    "#section-FluidContainer-2": {

        "& .root-0-2-47, & .root-0-2-96": {
            maxHeight: "unset"
        },

        "& img": {
            [Utilities.Jss.mediaQueryMaxWidth(haasmontConfig.grid.breakpoints.md)]: {
                filter: "opacity(0.5)"
            }
        }
    },

    ".two-cols--section .container .bs-col": {
        paddingTop: "3em",
        paddingBottom: "3em",
    }
};

export const HaasmontTheme = haasmontTheme;