import {Service} from "services/service";

export class UserService extends Service {

    async login(username, password) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return this.axios.post(`auth/authenticate`, JSON.stringify({username, password}), config)
            .catch(this.catchHandle)
            .then(this.handleResponse);
    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            this.logout();
            document.location.reload(true);

            return;
        }

        if (response.status !== 200) {
            // probably bad username or password
            return Service.errorHandle({response: response});
        }

        return response;
    }

    catchHandle(responseObject) {
        return responseObject.response;
    };
}