const initialState = {
    sections: [],
    widgets: []
}
export const StaticDataReducer = (state = initialState, action) => {
    if (action.type === 'LOAD_STATIC_DATA') {
        return action.payload;
    }

    return state
};