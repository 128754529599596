import {Constants} from "../../../../constants";
import ReactDOMServer from "react-dom/server";
import {StoreFactory} from "store/store";
import {JssProvider, ThemeProvider} from "react-jss";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch} from "react-router";
import React from "react";
import {Section} from "components/section/section";
import {SharedStyles} from "components/app/shared-styles";
import {modeChangeSuccess} from "store/mode/actions/mode-change-success";
import csso from 'csso';
import {loaderChangeSuccess} from "store/loader/actions/loader-change-success";
import {loggedUserGetter} from "../../../../helpers/logged-user-getter";

const autoprefixer = require('autoprefixer');
const postcss = require('postcss');

export const createSaveProjectAction = (deps) =>
    (project, theme) => //ThunkAction
        async (dispatch) => { //Promise

            const {sheets, projectService} = deps;

            dispatch(loaderChangeSuccess(true));

            dispatch(modeChangeSuccess(Constants.Mode.VIEW));

            for (let index = 0; index < project.pages.length; index++) {
                project.pages[index].html = ReactDOMServer.renderToString(getStaticContent(deps, project, theme));
                project.pages[index].css = await getCompiledCss(sheets.toString());
            }

            ReactDOMServer.renderToString(getSharedCss(sheets, theme));

            //Remove duplicate records (pages records ~ sheets.registry.classes === {empty}) from sheets
            while (sheets.registry.some(s => Object.keys(s.classes).length > 0)) {
                const itemToRemove = sheets.registry.find(s => Object.keys(s.classes).length > 0);
                sheets.remove(itemToRemove);
            }

            project.baseCss = await getCompiledCss(sheets.toString());

            const user = loggedUserGetter();
            await projectService.saveProject(user.id, project);

            //TODO: HOTFIX
            document.location.reload();

            /*dispatch(savePagesSuccess(project.pages));

            // Back to CMS mode - continue in replacing
            dispatch(modeChangeSuccess(Constants.Mode.CMS));

            dispatch(loaderChangeSuccess(false));*/
        };


const getStaticContent = (deps, project, theme) => {

    const store = new StoreFactory().getStore();

    return (
        <JssProvider registry={deps.sheets}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Switch>
                            {project.pages.map((page, index) => (
                                <Route key={index} path={page.url}
                                       component={() => getAppContentForStaticHtml(page.sections)}/>
                            ))}
                        </Switch>
                    </Router>
                </ThemeProvider>
            </Provider>
        </JssProvider>
    );
};

const getAppContentForStaticHtml = (sections) => {
    return (<div className="iws-cms-core">

        <div className="sections">

            {sections.map((section, index) => (
                <div className={"section-wrapper"} key={index} id={`section-${section.componentName}-${index}`}>
                    <Section
                        key={index}
                        index={index}
                        section={section}
                        lastInSections={index === (sections.length -1)}
                    />
                </div>
            ))}
        </div>
    </div>);
};

const getSharedCss = (sheets, theme) => {

    const store = new StoreFactory().getStore();

    return (
        <JssProvider registry={sheets}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <SharedStyles/>
                </ThemeProvider>
            </Provider>
        </JssProvider>
    )
};

const getCompiledCss = async (css) => {
    const compiledCss = await postcss([autoprefixer]).process(css).css;
    return csso.minify(compiledCss).css;
};