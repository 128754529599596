import React, {useState} from "react";
import {Editor} from "../../editor/editor";
import {useDispatch, useSelector} from "react-redux";
import {updateWidgetContents} from "store/pages/action-creator";
import {Constants} from "../../../constants";
import {fillWidgetContents} from "components/sections/sections";
import {CreateTheme} from "hooks/theme";
import {ObjectActions} from "components/object-actions/object-actions";
import {Utilities} from "utilities/utilities";
import {FaEdit} from "react-icons/fa";

import useStyles from "./action-button.styles";
import {MotiveSelector} from "components/motive-selector/motive-selector";

export const ActionButton = ({data, sectionIndex, customContents = null}) => {

    const classes = CreateTheme(useStyles);

    const dispatch = useDispatch();

    const project = useSelector(s => s.project);

    const [openEditor, setOpenEditor] = useState(false);

    const updateWidgetHandle = (value, valuePosition) => {
        dispatch(updateWidgetContents(value, data, project, valuePosition, sectionIndex))
    };

    let contents = customContents || [
        {position: 1, value: Constants.Strings.BUTTON_PLACEHOLDER}
    ];

    fillWidgetContents(data, contents);

    const ChildActions = () => (
        <div>
            <div onClick={() => setOpenEditor(true)}>
                <FaEdit className={"icon"}/>
            </div>

            <div>
                <MotiveSelector object={data}/>
            </div>
        </div>
    );

    return (
        <div className={`action-button--widget ${Utilities.Cms.getHiddenClasses(data)}`}>

            <ObjectActions
                sectionIndex={sectionIndex}
                object={data}
                type={Constants.ObjectTypes.WIDGET}
                childActions={<ChildActions/>}
            >
                <div className={`${Utilities.Cms.getHorizontalAlignmentClasses(data)}`}>
                    <div className={`${classes.root} ${Utilities.Cms.getMotiveClassName(data.motive, classes)}`}>
                        <Editor mode={Constants.Editor.Mode.BUTTON} openDialog={openEditor}
                                onClose={() => setOpenEditor(false)}
                                onSave={(value) => updateWidgetHandle(value, contents[0].position)}
                                currentValue={contents[0].value}/>
                    </div>
                </div>
            </ObjectActions>
        </div>
    )
};