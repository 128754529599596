import React, {useState} from "react";
import {Editor as TinyMCE} from '@tinymce/tinymce-react';
import {useSelector} from "react-redux";
import {Constants} from "../../constants";
import Button from "react-bootstrap/Button";
import useStyles from "./editor.styles";
import {CreateTheme} from "hooks/theme";
import {Utilities} from "utilities/utilities";
import {Modal} from "../modal/modal";

export const Editor = ({openDialog, onClose, onSave, currentValue, mode = Constants.Editor.Mode.LONG_TEXT}) => {

    const classes = CreateTheme(useStyles, true);

    const cmsMode = useSelector(s => s.mode);

    const theme = useSelector(s => s.theme);

    const [value, setValue] = useState(currentValue);

    const handleChange = (value) => {
        setValue(value);
    };

    const getToolbar = () => "formatselect | bold italic underline link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | forecolor | hr | undo redo";

    const getPlugins = () => [
        'advlist autolink lists link image charmap print preview anchor visualblocks code insertdatetime media table paste code hr help',
    ];

    const getEditorColors = () => {

        const result = [];

        const colors = Utilities.Cms.getThemeColors(theme);

        colors.forEach((color) => {
            result.push(color.hex);
            result.push(color.name);
        });

        return result;
    };

    const getModeClass = () => {
        switch (mode) {

            case Constants.Editor.Mode.SINGLE_TEXT:
                return classes.textWrapSingleMode;

            case Constants.Editor.Mode.BUTTON:
                return classes.textWrapButton;

            default:
                return classes.textWrap;
        }
    };

    return (openDialog && cmsMode === Constants.Mode.CMS ?
        (<div className={"editor-component"}>
                <Modal onClose={onClose} open={openDialog} >
                    <TinyMCE
                        apiKey={"68llfopmhqkw6w2a8ik241tblphuww7ybgujdlcoindx3jyb"}
                        initialValue={value}
                        onEditorChange={handleChange}

                        init={{
                            height: "50vh",
                            skin: "oxide-dark",
                            content_css: 'dark',
                            menubar: false,
                            branding: false,
                            statusbar: false,
                            anchor_top: false,
                            anchor_bottom: false,
                            toolbar: getToolbar(),
                            plugins: getPlugins(),
                            color_map: getEditorColors(),
                        }}
                    />
                    <div className={"d-block text-right mt-2"}>
                        <Button variant={"primary"} onClick={() => onSave(value)}>Hotovo</Button>
                    </div>

                </Modal>
            </div>
        ) :
        <div className={getModeClass()} dangerouslySetInnerHTML={{__html: value}}/>);
};