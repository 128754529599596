import {createUseStyles} from 'react-jss'

const classes = createUseStyles(theme => {

    const currentTheme = theme.pages.projects;

    return {
        project: currentTheme.project,
        projectName: currentTheme.projectName
    }
});

export default classes;

